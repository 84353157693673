import {
  takeEvery, call, put, cancel,
  type SagaReturnType,
} from 'redux-saga/effects';
import * as uuid from 'uuid';

import Alert from 'components/Alert';

import * as api from 'services/api';
import type { Document } from 'services/api/task/document/types';
import type { UserTask } from 'services/api/task/types';

import { select } from 'store/utils/saga/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.addDocument.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.addDocument>) {
  const {
    userTaskId, data,
  } = action.payload;
  const userTask: UserTask | null = yield select(selectors.getById(userTaskId));

  if (!data) {
    yield cancel(); return;
  }

  const oldDocuments: Document[] = [...userTask?.documents || []];
  const newDocuments: Document[] = [...userTask?.documents || [], {
    id: uuid.v4(),
    userTaskId,
    file: {
      name: '',
      fileUrl: '',
      thumbnailUrl: '',
    },
    createdAt: '',
    wasDeleted: false,
  }];

  yield put(actions.updateDocuments(userTaskId, newDocuments));

  const response: SagaReturnType<typeof api.resource.task.document.add> = yield call(() => api.resource.task.document.add(userTaskId, data));
  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error 12');
    yield put(actions.updateDocuments(userTaskId, oldDocuments));
    yield put(actions.addDocumentDone(userTaskId, data));
    yield cancel(); return;
  }

  yield put(actions.updateDocuments(userTaskId, response.data));

  yield put(actions.addDocumentDone(userTaskId, data));
}
