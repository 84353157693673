import { BACKEND_URI } from '@env';
import {
  useCallback, useState,
} from 'react';
import {
  StyleSheet, ImageBackground,
} from 'react-native';
import { useRoute } from 'navigation/hooks';
import { useTranslation } from 'translations';

import { useDispatcher } from 'store/utils/redux/hooks';
import { unit } from 'utils';

import { Text, View } from 'components/Themed';
import Button from 'components/Button';
import Logo from 'components/Logo';
import CodeInput from 'screens/auth/elements/CodeInput';

const Login = () => {
  const route = useRoute<'Auth/Login'>();

  const { t } = useTranslation();
  const dispatch = useDispatcher();

  const [password, setPassword] = useState<string>('');

  const handlePasswordChange = useCallback((value) => {
    setPassword(value);
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch.auth.login(route.params.phone, password);
  }, [route.params.phone, password]);

  const handleKeyPress = useCallback((event) => {
    if (event.key.toLowerCase() === 'enter') {
      handleSubmit();
    }
  }, [password]);

  return (
    <ImageBackground source={{ uri: `${BACKEND_URI}images/splash.jpg` }} resizeMode="cover" style={styles.image}>
      <View style={styles.RequestCode}>
        <View style={styles.form}>
          <Logo style={styles.logo} />
          <View style={styles.text}>
            <Text style={{ fontWeight: '500' }} size={24}>DocsToDo</Text>
          </View>
          <Text size={12} colorName="variant3">{t('codeFromSms')}</Text>
          <CodeInput style={styles.input} value={password} onChange={handlePasswordChange} onKeyPress={handleKeyPress} />
          <Button onPress={handleSubmit} style={styles.button} width="full">
            <Text size={16} colorName="variant5">{t('login')}</Text>
          </Button>
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  RequestCode: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    paddingTop: unit(41),
    paddingBottom: unit(13),
    alignItems: 'center',
  },
  form: {
    width: unit(334),
    height: unit(384),
    backgroundColor: '#FFFFFFF2',
    paddingVertical: unit(16),
    paddingHorizontal: unit(32),
    borderRadius: unit(16),
  },
  text: {
    alignItems: 'center',
    paddingBottom: unit(50),
  },
  textCompany: {
    alignItems: 'center',
    paddingBottom: unit(21),
  },
  input: {
    marginTop: unit(1),
    marginBottom: unit(3),
  },
  button: {
    marginTop: unit(16),
  },
  image: {
    flex: 1,
    justifyContent: 'space-around',
  },
});

export default Login;
