import request from 'services/api/request';
import credentials from 'services/api/credentials';
import type { Result } from 'services/api/types';
import type { ProfileEntity } from 'services/api/user/types';

export default async (
  phoneNumber: string,
  userPassword: string,
): Promise<Result<ProfileEntity>> => {
  const { data, error } = await request.post<ProfileEntity>('/session/login', {
    body: {
      phone: phoneNumber,
      loginCode: userPassword,
    },
    useCredentials: false,
  });

  if (error || !data?.sessionToken) {
    await credentials.clear();
    return {
      data: null,
      error,
    };
  }

  await credentials.set(data.sessionToken);
  return {
    data,
    error,
  };
};
