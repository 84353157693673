import {
  type FC, Fragment, memo, useCallback, useMemo,
} from 'react';
import {
  type StyleProp, StyleSheet, type ViewStyle,
} from 'react-native';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import pluralize from 'pluralize';

import { Text, View } from 'components/Themed';
import dialog from 'components/Dialog';

import * as userTaskStore from 'store/nodes/userTask';
import { unit } from 'utils';
import Button from 'components/Button';
import Picture from 'components/Picture';
import { useDispatcher } from 'store/utils/redux/hooks';
import Icon from 'components/Icon';
import ModalPreview from 'components/ModalPreview';
import useThemeColor from 'hooks/useThemeColor';
import DocumentPicker from 'components/DocumentPicker';
import { useColorBackground } from 'hooks/useColorBackground';
import Lottie from 'components/Lottie';
import { useTranslation } from 'translations';

interface UserTaskProps {
  idTask: number,
  style?: StyleProp<ViewStyle>
  status: string,
}

const Index: FC<UserTaskProps> = (props) => {
  const { idTask, style, status } = props;

  const { t, direction } = useTranslation();
  const dispatcher = useDispatcher();

  const borderColor = useThemeColor<string>({}, 'variant4');

  const handleSubmit = useCallback((event, context) => {
    const { idTask: taskId } = context;
    dialog.show(
      {
        title: t('submitTaskForReview'),
        text: t('submitConfirmationMessage'),
      },
      [{
        label: t('cancel'),
        variant: 'outlined',
        colorName: 'variant3',
        textColorName: 'variant3',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
        },
      }, {
        label: t('submit'),
        variant: 'outlined',
        colorName: 'error',
        textColorName: 'error',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
          dispatcher.userTask.submit(taskId);
        },
      }],
    );
  }, [dispatcher.userTask, idTask]);

  const handleRemove = useCallback((event, context) => {
    const { idTask: taskId } = context;
    dialog.show(
      {
        title: t('removeTaskForReview'),
        text: t('removeFromReviewMessage'),
      },
      [{
        label: t('cancel'),
        variant: 'outlined',
        colorName: 'variant3',
        textColorName: 'variant3',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
        },
      }, {
        label: t('remove'),
        variant: 'outlined',
        colorName: 'error',
        textColorName: 'error',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
          dispatcher.userTask.remove(taskId);
        },
      }],
    );
  }, [dispatcher.userTask, idTask]);

  const handleRemoveDocument = useCallback((event, context) => {
    const { id: documentId, userTaskId } = context;
    dialog.show(
      {
        title: t('deleteDocument'),
        text: t('deleteConfirmationMessage'),
      },
      [{
        label: t('cancel'),
        variant: 'outlined',
        colorName: 'variant3',
        textColorName: 'variant3',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
        },
      }, {
        label: t('delete'),
        variant: 'outlined',
        colorName: 'error',
        textColorName: 'error',
        handle: (dialogId: string) => {
          dialog.hide(dialogId);
          dispatcher.userTask.removeDocument(documentId, userTaskId);
        },
      }],
    );
  }, []);

  const handleDocumentChange = useCallback((result) => {
    const { uri } = result;
    dispatcher.userTask.addDocument(idTask, uri);
  }, [idTask]);

  const data = useSelector(userTaskStore.selectors.getById(idTask));
  const dueDate = moment(data.dueDate).format('DD MMM, YYYY');
  const approved = moment(data.approvedAt).format('DD MMM, YYYY');
  const submittedAt = moment(data.submittedAt).format('DD MMM, YYYY');

  const styleFinal = useMemo((): StyleProp<ViewStyle> => ({
    ...StyleSheet.flatten(styles.exampleButton),
    borderColor,
  }), [borderColor]);

  const countDocuments = data.documents.length;
  const color = useColorBackground(status);

  return (
    <View colorName="variant6" style={[style, styles.UserTask, { backgroundColor: color }]}>
      <View>
        <Text style={{ direction }} colorName="text" size={24}>{data.task.name}</Text>
      </View>
      <View style={styles.topForm}>
        <View style={styles.taskDetails}>
          <Text colorName="variant2" size={12}>{`${t('taskDetails')}:`}</Text>
          <Text colorName="text" size={16}>{`${data.task.description}`}</Text>
        </View>
        <View style={styles.dueDate}>
          {status === 'to_do' && (
          <>
            <Text colorName="variant2" size={12}>{`${t('dueDate')}:`}</Text>
            <Text colorName="text" size={16}>{`${dueDate}`}</Text>
          </>
          )}
          {status === 'waiting_for_review' && (
          <>
            <Text colorName="variant2" size={12}>{`${t('submittedAt')}:`}</Text>
            <Text colorName="text" size={16}>{`${submittedAt}`}</Text>
          </>
          )}
          {status === 'approved' && (
          <>
            <Text colorName="variant2" size={12}>{`${t('approvedAt')}:`}</Text>
            <Text colorName="text" size={16}>{`${approved}`}</Text>
          </>
          )}
        </View>
        {!!data.task.exampleUrl && (
          <ModalPreview src={data.task.exampleUrl}>
            <View style={styleFinal}>
              <Text colorName="variant4" style={styles.exampleButtonText} size={13}>{t('showExample')}</Text>
            </View>
          </ModalPreview>
        )}
        {!data.task.exampleUrl && (
        <View style={styles.emptyView} />
        )}
      </View>
      <View style={styles.notes}>
        <View colorName="error" style={styles.notesBorder}>
          <Text colorName="variant5" size={12}>{t('notes')}</Text>
        </View>
        <Text colorName="text" size={16}>{`${data.notes}`}</Text>
      </View>
      <Text weight={500} size={16} colorName="variant2">
        {`${t('documents')}:`}
      </Text>
      <View style={styles.imageMap}>
        {data.documents.map((document) => {
          const { id, userTaskId, file } = document;

          return (
            <Fragment key={id}>
              {typeof id === 'string' && (
                <View style={[styles.uploadingDocument, styles.picture]} colorName="variant5">
                  <Lottie name="LoadingCircle" width={97} loop />
                  <Text style={styles.textUpload} colorName="variant4" size={18} weight={700}>{t('uploading')}</Text>
                </View>
              )}
              {typeof id === 'number' && (
                <ModalPreview style={styles.picture} src={file.fileUrl}>
                  <Picture style={styles.picture} src={file.thumbnailUrl} />
                  <Button
                    type="button-icon"
                    height={32}
                    width={32}
                    radius="full"
                    colorName="variant5"
                    style={styles.removeButton}
                    context={{ id, userTaskId }}
                    onPress={handleRemoveDocument}
                  >
                    <Icon key={file.fileUrl} name="RemoveIcon" colorName="error" size={28} />
                  </Button>
                </ModalPreview>
              )}
            </Fragment>
          );
        })}
        {data.status !== 'approved' && (
          <DocumentPicker acceptedTypes="image/jpeg,image/png,image/tiff,application/pdf" onChange={handleDocumentChange}>
            <View style={styles.uploadDocsView} colorName="variant5">
              <View style={styles.circleIcon} colorName="statusIcon">
                <Icon colorName="variant5" name="UploadDocsIcon" size={56} />
              </View>
              <Text style={styles.textUpload} colorName="variant4" size={18} weight={700}>{t('uploadDocs')}</Text>
            </View>
          </DocumentPicker>
        )}
      </View>
      {data.status !== 'approved' && (
        <View style={styles.bottomForm}>
          {data.status === 'to_do' && (
          <View style={styles.description}>
            <Text colorName="text" weight={400} size={15}>{t('BeforeSubmittingDocuments')}</Text>
          </View>
          )}
          {data.status === 'waiting_for_review' && (
            <View style={styles.description}>
              <Text colorName="text" weight={400} size={15}>{t('YourTaskInReview')}</Text>
            </View>
          )}
          <View style={styles.statusButton}>
            {data.status === 'waiting_for_review' && (
              <Button
                context={{ idTask }}
                onPress={handleRemove}
              >
                <Text size={16} colorName="variant5">{t('removeFromReview')}</Text>
              </Button>
            )}
            {data.status === 'to_do' && (
              <Button
                context={{ idTask }}
                endIcon={<Icon colorName="variant5" size={12} name="ArrowLeftIcon" />}
                onPress={handleSubmit}
              >
                <Text size={16} colorName="variant5">{t('submitForReview')}</Text>
              </Button>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  UserTask: {
    width: '100px',
    marginLeft: unit(18),
    marginRight: unit(46),
    paddingLeft: unit(30),
    paddingRight: unit(54),
    paddingTop: unit(37),
    paddingBottom: unit(27),
    borderRadius: unit(8),
  },
  topForm: {
    paddingTop: unit(20),
    paddingBottom: unit(15),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  taskDetails: {
    flexDirection: 'column',
  },
  dueDate: {
    flexDirection: 'column',
  },
  emptyView: {
    width: unit(154),
    height: unit(40),
  },
  bottomForm: {
    marginTop: unit(27),
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  notes: {
    flexDirection: 'column',
    marginBottom: unit(23),
  },
  imageMap: {
    flexDirection: 'row',
    marginTop: unit(27),
    marginBottom: unit(35),
  },
  notesBorder: {
    borderRadius: unit(4),
    width: unit(42),
    alignItems: 'center',
  },
  uploadingDocument: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: unit(8),
    alignItems: 'center',
    justifyContent: 'center',
  },
  picture: {
    position: 'relative',
    width: unit(165),
    height: unit(216),
    marginRight: unit(24),
  },
  fileName: {
    width: unit(165),
    marginTop: unit(9),
  },
  textCount: {
    flexDirection: 'column',
    height: unit(30),
  },
  exampleButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    borderRadius: 8,
    borderWidth: unit(2),
    width: unit(154),
    height: unit(40),
  },
  exampleButtonText: {
    fontSize: 14,
    lineHeight: 30,
    fontWeight: 'bold',
  },
  statusButton: {
  },
  description: {
    maxWidth: unit(320),
    paddingRight: unit(40),
  },
  removeButton: {
    position: 'absolute',
    top: unit(-10),
    right: unit(-10),
  },
  uploadDocsView: {
    width: unit(165),
    height: unit(216),
    borderRadius: unit(8),
    borderStyle: 'dashed',
    borderWidth: unit(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleIcon: {
    width: unit(100),
    height: unit(100),
    borderRadius: unit(50),
    justifyContent: 'center',
    alignItems: 'center',
  },
  textUpload: {
    marginTop: unit(18),
  },
});

export default memo(Index);
