import {
  useCallback, useEffect, useState,
  type FC, type ReactText,
} from 'react';
import {
  createPortal,
} from 'react-dom';
import {
  ToastContainer, Slide, toast,
  type ToastPosition, type ToastContent, type ToastOptions,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Method = (content: ToastContent, options?: ToastOptions<any> | undefined) => ReactText;

type MessageType = 'info' | 'success' | 'error';

const getPosition = (): ToastPosition => {
  const width = window.innerWidth;
  if (width <= 480) {
    return toast.POSITION.BOTTOM_CENTER;
  }
  return toast.POSITION.TOP_RIGHT;
};

const getMethod = (type: MessageType): Method => {
  if (type === 'success') {
    return toast.success;
  }
  if (type === 'error') {
    return toast.error;
  }
  return toast.info;
};

const store = new class Store {
  private ids: ReactText[] = [];

  public add = (toastId: ReactText) => {
    this.ids.push(toastId);
  };

  public remove = (toastId: ReactText) => {
    const index = this.ids.findIndex((id) => id === toastId);
    if (index === -1) {
      return;
    }
    this.ids.splice(index, 1);
  };

  public hasIds = (): boolean => this.ids.length > 0;

  public getAll = (): ReactText[] => [...this.ids];
}();

// @ts-ignore
window.store = store;

export const Init: FC = () => {
  const [position, setPosition] = useState<ToastPosition>(getPosition());

  const handleResize = useCallback(() => {
    const newPosition = getPosition();
    if (position !== newPosition) {
      setPosition(newPosition);
      toast.dismiss();
    }
  }, [position]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return createPortal(
    <ToastContainer
      position={position}
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      transition={Slide}
      theme="light"
      style={{ zIndex: 20000 }}
    />,
    document.body,
  );
};

const show = (type: MessageType, message: string): void => {
  const method = getMethod(type);
  if (getPosition() === 'bottom-center') {
    store.getAll().forEach((id) => {
      toast.dismiss(id);
    });
    setTimeout(() => {
      const toastId = method(message, {
        onClose: () => {
          store.remove(toastId);
        },
      });
      store.add(toastId);
    }, store.hasIds() ? 900 : 0);
    return;
  }
  method(message);
};

export const error = (message: string): void => {
  show('error', message);
};

export const success = (message: string): void => {
  show('success', message);
};
