import {
  useEffect, useState, useCallback,
  type FC,
} from 'react';
import { Platform } from 'react-native';
import { Provider } from 'react-redux';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { store } from 'store';
import * as appActions from 'store/nodes/app/actions';

import { TranslationProvider } from 'translations';

import Alert from 'components/Alert';
import Main from 'components/Main';
import { Dialogs } from 'components/Dialog';

import useCachedResources from './hooks/useCachedResources';

const App: FC = () => {
  const isLoadingComplete = useCachedResources();

  const [isAppReady, setAppReady] = useState(false);
  const [isResourcesReady, setResourcesReady] = useState(Platform.OS === 'web');
  const [isNavigationReady, setNavigationReady] = useState(true);

  useEffect(() => {
    store.subscribe(() => {
      if (store.getState().app.ready === true) {
        setAppReady(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isLoadingComplete) {
      console.timeLog('Time', 'Resource ready');
      setResourcesReady(true);
    }
  }, [isLoadingComplete]);

  useEffect(() => {
    if (isResourcesReady && isNavigationReady) {
      store.dispatch(appActions.ready());
    }
  }, [isResourcesReady, isNavigationReady]);

  const handleNavigationReady = useCallback(() => {
    console.timeLog('Time', 'Navigation ready');
    setNavigationReady(true);
  }, []);

  return (
    <SafeAreaProvider>
      <TranslationProvider>
        <Provider store={store}>
          <Main onNavigationReady={handleNavigationReady} isAppReady={isAppReady && isNavigationReady} />
          <Dialogs />
        </Provider>
        <Alert.Init />
      </TranslationProvider>
    </SafeAreaProvider>
  );
};

export default App;
