import {
  type FC,
  useEffect,
} from 'react';
import { StatusBar } from 'expo-status-bar';

import Preparing from 'components/Preparing';
import Splash from 'components/Splash';

import Navigation from 'navigation';

import { useSelector } from 'store/utils/redux/hooks';
import useColorScheme from 'hooks/useColorScheme';
import useThemeColor from 'hooks/useThemeColor';

interface MainProps {
  isAppReady: boolean,
  onNavigationReady?: () => void,
}

const Main: FC<MainProps> = (props) => {
  const {
    isAppReady = false,
    onNavigationReady,
  } = props;
  const colorScheme = useColorScheme();
  const background = useThemeColor<string>({}, 'background');

  const isAppPrepared = useSelector((state) => state.app.prepared);

  const showSplash = !isAppReady;
  const showPreparing = isAppReady && !isAppPrepared;
  const showApp = isAppReady && isAppPrepared;

  useEffect(() => {
    document.body.style.background = background;
  }, [background]);

  return (
    <>
      {showApp && <StatusBar />}
      <Navigation colorScheme={colorScheme} onReady={onNavigationReady} />
      {showSplash && <Splash />}
      {showPreparing && <Preparing />}
    </>
  );
};

export default Main;
