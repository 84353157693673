import { createAction } from '@reduxjs/toolkit';

import type { ProfileEntity } from 'services/api/user/types';

const capitalize = (text: string) => {
  if (!text) {
    return text;
  }
  if (text.length === 1) {
    return text.toUpperCase();
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const reset = createAction('USER/RESET');

export const loadProfile = createAction('USER/LOAD_PROFILE', (login?: 'my' | string) => ({
  payload: {
    login: login || 'my',
  },
}));

export const loadProfileDone = createAction('USER/LOAD_PROFILE_DONE', (login: string) => ({
  payload: {
    login,
  },
}));

export const setProfile = createAction('USER/SET_PROFILE', (data: ProfileEntity) => {
  return ({
    payload: {
      data: {
        ...data,
        firstName: capitalize(data?.firstName || ''),
        lastName: capitalize(data?.lastName || ''),
        isMy: true,
      } as ProfileEntity,
    },
  });
});
