import { createReducer } from '@reduxjs/toolkit';

import type { AuthStore } from './types';
import * as actions from './actions';

const initialState: AuthStore = {
  login: {
    isLoading: false,
    result: {
      success: null,
      error: null,
    },
  },
  signOut: {
    isLoading: false,
    result: {
      success: null,
      error: null,
    },
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.login, (state, action) => {
    state.login.isLoading = true;
    state.login.result.success = null;
    state.login.result.error = null;
  })
  .addCase(actions.loginDone, (state, action) => {
    state.login.isLoading = false;
  })
  .addCase(actions.logout, (state, action) => {
    state.signOut.isLoading = true;
    state.signOut.result.success = null;
    state.signOut.result.error = null;
  })
  .addCase(actions.logoutDone, (state, action) => {
    state.signOut.isLoading = false;
    state.signOut.result.success = !action.payload?.error;
    state.signOut.result.error = action.payload?.error ? action.payload.error.message : null;
  }));
