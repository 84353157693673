import { BACKEND_URI } from '@env';
import {
  useCallback, useState,
} from 'react';
import {
  TextInput, View, StyleSheet, ImageBackground, I18nManager,
} from 'react-native';
import { useTranslation } from 'translations';

import { useDispatcher } from 'store/utils/redux/hooks';
import { unit } from 'utils';
import { Text } from 'components/Themed';
import Button from 'components/Button';
import Logo from 'components/Logo';
import { useRoute } from 'navigation/hooks';

const RequestCode = () => {
  const route = useRoute<'Auth/RequestCode'>();

  const dispatch = useDispatcher();
  const { t } = useTranslation();
  const [phone, setPhone] = useState<string>('');

  const handlePhoneChange = useCallback((value) => {
    setPhone(value);
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch.auth.requestCode(phone);
  }, [phone]);

  const handleKeyPress = useCallback((event) => {
    if (event.key.toLowerCase() === 'enter') {
      handleSubmit();
    }
  }, [phone]);

  return (
    <ImageBackground source={{ uri: `${BACKEND_URI}images/splash.jpg` }} resizeMode="cover" style={styles.image}>
      <View style={styles.RequestCode}>
        <View style={styles.form}>
          <Logo style={styles.logo} />
          <View style={styles.text}>
            <Text style={{ fontWeight: '500' }} size={24}>DocsToDo</Text>
          </View>
          <Text size={12} colorName="variant3">{t('enterPhoneNumber')}</Text>
          <TextInput
            maxLength={14}
            textContentType="telephoneNumber"
            style={styles.input}
            value={phone}
            onChangeText={handlePhoneChange}
            onKeyPress={handleKeyPress}
          />
          <Button width="full" style={styles.button} onPress={handleSubmit}>
            <Text size={16} colorName="variant5">{t('enter')}</Text>
          </Button>
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  RequestCode: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    paddingTop: unit(41),
    paddingBottom: unit(13),
    alignItems: 'center',
  },
  text: {
    alignItems: 'center',
    paddingBottom: unit(50),
  },
  textCompany: {
    alignItems: 'center',
    paddingBottom: unit(21),
  },
  button: {
    marginTop: unit(16),
  },
  form: {
    width: unit(334),
    height: unit(384),
    backgroundColor: '#FFFFFFF2',
    paddingVertical: unit(16),
    paddingHorizontal: unit(32),
    borderRadius: unit(16),
  },
  input: {
    marginTop: unit(1),
    marginBottom: unit(16),
    borderBottomWidth: unit(0.5),
    paddingBottom: unit(2),
    borderColor: '#949494',
    fontSize: unit(16),
    outlineStyle: 'none',
    textAlign: I18nManager.isRTL ? 'right' : 'left',
  },
  image: {
    flex: 1,
    justifyContent: 'space-around',
  },
});

export default RequestCode;
