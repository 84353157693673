import { useMemo } from 'react';

export const useColorBackground = (status: string): string => {
  return useMemo(() => {
    let color = '';
    if (status === 'to_do') {
      color = '#EBF3FF';
    }
    if (status === 'waiting_for_review') {
      color = '#F9FAE4';
    }
    if (status === 'approved') {
      color = '#E4FAE5';
    }
    return color;
  }, [status]);
};
