import request from 'services/api/request';
import type { Result } from 'services/api/types';

import type { ProfileEntity } from 'services/api/user/types';

export default async (): Promise<Result<ProfileEntity>> => {
  const { data, error } = await request.get<ProfileEntity>('/user');
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }
  return {
    data,
    error,
  };
};
