import { useCallback } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';

import { useNavigate } from 'navigation/hooks';

const NotFound = () => {
  const navigate = useNavigate();

  const handleToHome = useCallback(() => {
    navigate('Home');
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>This page doesn&apos;t found.</Text>
      <TouchableOpacity onPress={handleToHome} style={styles.link} activeOpacity={0.8}>
        <Text style={styles.linkText}>Go to home page!</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});

export default NotFound;
