import {
  type ReactElement,
  memo, Fragment,
} from 'react';
import {
  ScrollView, type StyleProp, type ViewStyle, StyleSheet,
} from 'react-native';

import { View } from 'components/Themed';
import { unit } from 'utils';
import { useColorBackground } from 'hooks/useColorBackground';
import Item from './elements/Item';

type UserTasksProps = {
  style?: StyleProp<ViewStyle>
  sequence: {
    id: number
  }[],
  selectedId?: number | null,
  onSelect: (id: number) => void,
  status: string,
};

const UserTasks = (props: UserTasksProps): ReactElement => {
  const {
    style, sequence, selectedId, onSelect, status,
  } = props;

  const color = useColorBackground(status);

  return (
    <View style={[style, styles.UserTasks]}>
      <ScrollView style={styles.scroll}>
        <View style={{ backgroundColor: color, borderRadius: unit(8) }}>
          {sequence.map((item, index, array) => {
            const isLast = index === array.length - 1;
            return (
              <Fragment key={item.id}>
                <Item status={status} key={item.id} id={item.id} isSelected={item.id === selectedId} onPress={onSelect} />
                {!isLast && <View style={styles.separator} />}
              </Fragment>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  UserTasks: {
    width: unit(276),
  },
  scroll: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  separator: {
    marginHorizontal: unit(24),
    borderBottomWidth: unit(1),
    borderStyle: 'dashed',
    borderColor: '#000000',
    opacity: 0.3,
  },
});

export default memo(UserTasks);
