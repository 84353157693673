import {
  type FC, memo,
} from 'react';
import {
  type StyleProp, StyleSheet, type ViewStyle,
} from 'react-native';

import { Text, View } from 'components/Themed';

import { unit } from 'utils';
import { useColorBackground } from 'hooks/useColorBackground';
import { useTranslation } from 'translations';

interface EmptyTaskProps {
  style?: StyleProp<ViewStyle>
  status: string,
}

const Index: FC<EmptyTaskProps> = (props) => {
  const { style, status } = props;

  const color = useColorBackground(status);
  const { t } = useTranslation();

  return (
    <View style={[style, styles.EmptyTaskPage, { backgroundColor: color }]}>
      <Text style={styles.text} colorName="text" size={28}>{t('emptyPage')}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  EmptyTaskPage: {
    width: '85%',
    height: '50%',
    borderRadius: unit(8),
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: unit(10),
  },
  text: {

  },
});

export default memo(Index);
