import React from 'react';
import { View, type StyleProp, type ViewStyle } from 'react-native';
import Logo from './assets/Logo.svg';

type LogoSVGProps = {
  style?: StyleProp<ViewStyle>,
}

const LogoSVG = (props: LogoSVGProps) => {
  const { style } = props;
  return (
    <View style={style}>
      <Logo />
    </View>
  );
};

export default LogoSVG;
