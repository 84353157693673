import { createAction } from '@reduxjs/toolkit';

import type { UserTask } from 'services/api/task/types';

import type { Document } from 'services/api/task/document/types';

export const loadList = createAction('USER_TASK/LOAD_LIST');

export const loadListDone = createAction('USER_TASK/LOAD_LIST_DONE');

export const setItems = createAction('USER_TASK/SET_TASK', (items: UserTask[] | null) => ({
  payload: {
    items,
  },
}));

export const submit = createAction('USER_TASK/SUBMIT', (id: number) => ({
  payload: {
    id,
  },
}));

export const submitDone = createAction('USER_TASK/SUBMIT_DONE', (id: number) => ({
  payload: {
    id,
  },
}));

export const remove = createAction('USER_TASK/REMOVE', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeDone = createAction('USER_TASK/REMOVE_DONE', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeDocument = createAction('USER_TASK/REMOVE_DOCUMENT', (id: number, userTaskId: number) => ({
  payload: {
    id,
    userTaskId,
  },
}));

export const removeDocumentDone = createAction('USER_TASK/REMOVE_DOCUMENT_DONE', (id: number, userTaskId: number) => ({
  payload: {
    id,
    userTaskId,
  },
}));

export const updateDocuments = createAction('USER_TASK/UPDATE_DOCUMENTS', (userTaskId: number, documents: Document[]) => ({
  payload: {
    userTaskId,
    documents,
  },
}));

export const addDocument = createAction('USER_TASK/ADD_DOCUMENT', (userTaskId: number, data: string) => ({
  payload: {
    userTaskId,
    data,
  },
}));

export const addDocumentDone = createAction('USER_TASK/ADD_DOCUMENT_DONE', (userTaskId: number, data: string) => ({
  payload: {
    userTaskId,
    data,
  },
}));

export const setStatus = createAction('USER_TASK/SET_STATE', (id: number, status: 'waiting_for_review' | 'to_do' | 'approved') => ({
  payload: {
    id,
    status,
  },
}));
