import request from 'services/api/request';
import type { Result } from 'services/api/types';
import type { UserTask } from 'services/api/task/types';
import type { Document } from 'services/api/task/document/types';

export default async (
  userTaskId: number,
  data: string,
): Promise<Result<Document[]>> => {
  const response = await request.post<UserTask>(`/user/task/${userTaskId}/document/add`, {
    body: {
      data,
    },
  });
  if (response.error || !response.data) {
    return {
      data: null,
      error: response.error,
    };
  }
  return {
    data: response.data.documents,
    error: null,
  };
};
