import list from './list';
import submit from './submit';
import remove from './remove';
import document from './document/route';

export default {
  list,
  submit,
  remove,
  document,
};
