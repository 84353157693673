import {
  type PropsWithChildren,
  useState, useEffect, createContext, useCallback, useMemo, useContext,
} from 'react';
import { I18nManager } from 'react-native';
import i18n from 'i18next';
import { initReactI18next, useTranslation as useTranslationOrigin } from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { en, he } from './dictionaries';

const defaultLang = 'he';

const locales = {
  'en': 'English',
  'he': 'Hebrew',
} as const;

const dictionaries = {
  en: {
    translation: en,
  },
  he: {
    translation: he,
  },
};

type Locale = Readonly<keyof typeof locales>;

type Context = {
  locales: typeof locales,
  locale: Locale,
  direction: 'ltr' | 'rtl',
  setLocale: (locale: Locale | string) => void,
}

const TranslationContext = createContext<Context>({
  locales,
  locale: defaultLang,
  direction: 'ltr',
  setLocale: () => undefined,
});

i18n
  .use(initReactI18next)
  .init({
    resources: dictionaries,
    fallbackLng: defaultLang,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

const getDirection = (locale: Locale): 'ltr' | 'rtl' => {
  const directions: Record<Locale, 'ltr' | 'rtl'> = {
    en: 'ltr',
    he: 'rtl',
  };
  return directions[locale || defaultLang] || 'ltr';
};

export const useTranslation = () => {
  const { t, ready } = useTranslationOrigin();
  const context = useContext(TranslationContext);

  return useMemo(() => ({
    t,
    ready,
    ...context,
  }), [t, ready, context]);
};

export const TranslationProvider = (props: PropsWithChildren<unknown>) => {
  const { children } = props;

  const [value, setValue] = useState<Omit<Context, 'setLocale' | 'locales'>>({
    locale: defaultLang,
    direction: 'ltr',
  });
  const setLocale = useCallback((locale: Locale | string) => {
    const direction = getDirection(locale as Locale);
    i18n.changeLanguage(locale)
      .then(() => {
        I18nManager.forceRTL(direction === 'rtl');
        setValue({
          locale: locale as Locale,
          direction,
        });
        return Promise.resolve();
      })
      .then(() => AsyncStorage.setItem('i18nextLng', locale))
      .catch((error) => console.log(`TRANSLATION: ${error.message}`));
  }, []);

  const init = useCallback(() => {
    AsyncStorage.getItem('i18nextLng')
      .then((locale) => {
        setLocale((locale || defaultLang) as Locale);
      })
      .catch(() => {
        setLocale(defaultLang);
      });
  }, []);

  useEffect(() => {
    init();
  }, []);

  const currentValue = useMemo(() => ({
    locales,
    ...value,
    setLocale,
  }), [value, setLocale]);

  return (
    <TranslationContext.Provider value={currentValue}>{children}</TranslationContext.Provider>
  );
};
