import {
  memo, type ReactElement, useCallback, useMemo, useState,
} from 'react';
import {
  Image,
  Modal, StyleSheet, View, type ViewStyle, TouchableOpacity,
} from 'react-native';

import useThemeColor from 'hooks/useThemeColor';

import { unit } from 'utils';

import BlurView from 'components/BlurView';
import type { ModalPreviewProps } from 'components/ModalPreview/types';

export const getMimetypeByExt = (ext: string): string => {
  const mimetypeByExt: Record<string, string> = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    pdf: 'application/pdf',
    zip: 'application/zip',
  };
  return mimetypeByExt[`${ext}`.toLowerCase()] || '';
};

export const getExt = (url?: string): string => {
  return `${url}`.replace(/.*\.([A-z0-9]{3,4})$/, '$1');
};

export const checkImage = (url: string): boolean => {
  if (!url) {
    return false;
  }
  const ext = getExt(url);
  return getMimetypeByExt(ext).startsWith('image/');
};

export const checkPdf = (url: string): boolean => {
  if (!url) {
    return false;
  }
  const ext = getExt(url);
  return getMimetypeByExt(ext) === 'application/pdf';
};

const ModalPreview = (props: ModalPreviewProps): ReactElement => {
  const {
    src,
    children,
    style,
  } = props;

  const isImage = checkImage(src);
  const isPdf = checkPdf(src);

  const [isVisible, setVisible] = useState(false);

  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const backDropBackground = useThemeColor<string>({ dark: '#FFFFFF44', light: '#00000044' }, 'view');

  const backDropStyle = useMemo<ViewStyle>(() => ({
    backgroundColor: backDropBackground,
    flex: 1,
  }), [backDropBackground]);

  return (
    <>
      <Modal
        animationType="fade"
        transparent
        visible={isVisible}
      >
        <View style={styles.modalView}>
          <TouchableOpacity onPress={handleClose} style={styles.backDrop} activeOpacity={0.8}>
            <BlurView style={[{ flex: 1 }, backDropStyle]} factor={3} />
          </TouchableOpacity>
          {isImage && (
            <Image resizeMode="center" style={styles.image} source={{ uri: src }} />
          )}
          {isPdf && (
            <View style={styles.pdf}>
              <iframe height="100%" width="100%" src={src} title={src} style={StyleSheet.flatten(styles.iframe)} />
            </View>
          )}
        </View>
      </Modal>
      <TouchableOpacity style={style} onPress={handleOpen} activeOpacity={0.8}>
        {children}
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  backDrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modalView: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  image: {
    width: '30%',
    height: '30%',
    border: 0,
    margin: 0,
    padding: 0,
  },
  pdf: {
    width: '60%',
    height: '95%',
    border: 0,
    margin: 0,
    padding: 0,
  },
  iframe: {
    borderWidth: 0,
    borderRadius: unit(6),
  },
});

export default memo(ModalPreview);
