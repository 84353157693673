import React, {
  type ReactElement, useMemo, useState, useCallback,
} from 'react';
import {
  Modal, Pressable, StyleSheet, TouchableOpacity, type StyleProp, type ViewStyle,
} from 'react-native';

import { useTranslation } from 'translations';
import { unit } from 'utils';

import { View, Text } from 'components/Themed';
import Icon, { type IconNames } from 'components/Icon';

const LanguagePicker = (): ReactElement => {
  const { locales, setLocale, locale } = useTranslation();

  const [isVisible, setVisible] = useState(false);

  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const iconName: Record<string, IconNames> = {
    en: 'EnLangIcon',
    he: 'HeLangIcon',
  };

  const languages = useMemo(() => Object.entries(locales).map(([name, label]) => ({
    name,
    label,
  })), [locales]);

  const styleFinal = useMemo((): StyleProp<ViewStyle> => ({
    ...StyleSheet.flatten(styles.centeredView),
    direction: 'ltr',
    marginLeft: unit(170),
  }), []);

  return (
    <View>
      <Modal
        animationType="fade"
        transparent
        visible={isVisible}
      >
        <TouchableOpacity
          onPress={handleClose}
          style={styles.backDrop}
          activeOpacity={0.8}
        />
        <View style={styleFinal}>
          <View style={styles.modalView}>
            {languages.map((language) => (
              <Pressable
                key={language.name}
                style={styles.item}
                onPress={() => {
                  setLocale(language.name);
                  setVisible(!isVisible);
                }}
              >
                <Icon direction="ltr" name={iconName[language.name]} size={20} />
                <Text style={styles.textStyle} size={15} weight={400}>{language.label}</Text>
              </Pressable>
            ))}
          </View>
        </View>
      </Modal>
      <Pressable
        style={[styles.button, styles.buttonOpen]}
        onPress={handleOpen}
      >
        <Text size={15} weight={400}>{locale}</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  backDrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginTop: unit(65),
    marginRight: unit(170),

  },
  modalView: {
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: unit(6),
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  item: {
    paddingVertical: unit(8),
    paddingHorizontal: unit(16),
    borderBottomWidth: 1,
    borderBottomColor: 'rgb(217, 225, 237)',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    borderRadius: unit(6),
    padding: unit(6),
    elevation: 2,
    marginRight: unit(24),
    marginLeft: unit(24),
    alignSelf: 'center',
  },
  buttonOpen: {
    backgroundColor: '#EBF3FF',
  },
  textStyle: {
    marginLeft: unit(8),
    marginRight: unit(8),
  },
});

export default LanguagePicker;
