import {
  Platform, Dimensions,
  type ScaledSize,
} from 'react-native';

export const unit = (value: number | undefined, dem?: ScaledSize): number => {
  const isWeb = Platform.OS === 'web';
  const finalDem = dem || Dimensions.get('window');
  const finalValue = typeof value === 'number' && finalDem.width < 375 ? ((value * finalDem.width) / 375) : value;
  if (!finalValue) {
    return undefined as unknown as number;
  }
  if (isWeb) {
    return `${finalValue / 16}rem` as unknown as number;
  }
  return finalValue;
};

export const getFontFamilyByWeight = (weight?: number): string => {
  const fontWeightMap: Record<number, string> = {
    400: 'roboto-regular',
    500: 'roboto-medium',
    700: 'roboto-bold',
  };

  if (!weight || typeof fontWeightMap[weight] === 'undefined') {
    return 'roboto-regular';
  }

  return fontWeightMap[weight];
};

export const getLineHeightByFontSize = (size: number): number => {
  const lineHeightMap: Record<number, number> = {
    10: 13,
    11: 13,
    12: 16,
    13: 18,
    15: 20,
    16: 21,
    17: 22,
    18: 22,
    20: 25,
    22: 27,
    28: 34,
    34: 41,
  };
  return lineHeightMap[size] || 13;
};
