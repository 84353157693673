import connect from 'store/utils/saga/connect';
import * as routing from './sagas/routing';
import * as ready from './sagas/ready';
import * as dialogHide from './sagas/dialogHide';

export default connect([
  ready,
  routing,
  dialogHide,
]);
