import connect from '../../utils/saga/connect';
import * as credentialListener from './sagas/credentialListener';
import * as checkAuth from './sagas/checkAuth';
import * as requestCode from './sagas/requestCode';
import * as login from './sagas/login';
import * as signOut from './sagas/signOut';

export default connect([
  credentialListener,
  checkAuth,
  requestCode,
  login,
  signOut,
]);
