import { type FC } from 'react';
import { Text, View } from 'react-native';

const Splash: FC = () => (
  <View>
    <Text>LOADING...</Text>
  </View>
);

export default Splash;
