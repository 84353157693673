import request from 'services/api/request';
import type { Result } from 'services/api/types';
import type { UserTask } from 'services/api/task/types';
import type { Document } from 'services/api/task/document/types';

export default async (
  id: number,
  userTaskId: number,
): Promise<Result<Document[]>> => {
  const { data, error } = await request.post<UserTask>(`/user/task/${userTaskId}/document/${id}/remove`);
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }
  return {
    data: data.documents,
    error,
  };
};
