import { createAction } from '@reduxjs/toolkit';

import { prepareActionDone } from 'store/utils/saga/actions';

export const checkAuth = createAction('AUTH/CHECK_AUTH');

export const checkAuthDone = createAction('AUTH/CHECK_AUTH_DONE', prepareActionDone);

export const requestCode = createAction('AUTH/REQUEST_CODE', (phoneNumber: string) => ({
  payload: {
    phoneNumber,
  },
}));

export const requestCodeDone = createAction('AUTH/REQUEST_CODE_DONE', prepareActionDone);

export const login = createAction('AUTH/LOGIN', (phoneNumber: string, userPassword: string) => ({
  payload: {
    phoneNumber,
    userPassword,
  },
}));

export const loginDone = createAction('AUTH/LOGIN_DONE', prepareActionDone);

export const logout = createAction('AUTH/LOGOUT');

export const logoutDone = createAction('AUTH/LOGOUT_DONE', prepareActionDone);

export const startCredentialListening = createAction('AUTH/START_CREDENTIAL_LISTENING');
