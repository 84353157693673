import { createAction } from '@reduxjs/toolkit';
import type { Body, ButtonAlias } from 'store/nodes/app/types';

export const ready = createAction('APP/READY');

export const prepared = createAction('APP/PREPARED');

export const routed = createAction('APP/ROUTED');

export const route = createAction('APP/ROUTE', () => ({
  payload: {},
}));

export const dialogShow = createAction('APP/DIALOG_SHOW', (id: string, body: Body, buttons: ButtonAlias[]) => ({
  payload: {
    id,
    body,
    buttons,
  },
}));

export const dialogHide = createAction('APP/DIALOG_HIDE', (id: string) => ({
  payload: {
    id,
  },
}));

export const dialogRemove = createAction('APP/DIALOG_REMOVE', (id: string) => ({
  payload: {
    id,
  },
}));
