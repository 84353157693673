import { matchPath } from 'react-router-dom';

import linking from '../../linking';
import type { ScreenParams } from '../../types';

export const getPathname = (): string => {
  if (typeof window !== 'undefined') {
    return window.location.pathname;
  }
  return '';
};

export const getRoute = <RouteScreenName extends keyof ScreenParams>() => {
  const pathname = getPathname();
  let params: Record<string, any> = {};
  let name: keyof ScreenParams = 'Error/NotFound';
  Object.entries(linking).forEach(([screenName, pattern]) => {
    if (name !== 'Error/NotFound') {
      return;
    }
    const match = matchPath(pattern, pathname);
    if (match) {
      name = screenName as keyof ScreenParams;
      params = match.params;
    }
  });
  return {
    name,
    params: params as ScreenParams[RouteScreenName],
    isScreen: <ScreenName extends keyof ScreenParams>(screenName: ScreenName): boolean => {
      return screenName === name;
    },
  };
};
