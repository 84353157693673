import {
  takeEvery, call, put, cancel,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';
import { navigate } from 'navigation/methods';
import * as api from 'services/api';

import * as actions from '../actions';

export const config = {
  action: actions.requestCode.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.requestCode>) {
  const { phoneNumber } = action.payload;
  const result: SagaReturnType<typeof api.resource.auth.requestCode> = yield call(() => api.resource.auth.requestCode(phoneNumber));
  if (result.error || !result.data) {
    Alert.error(result?.error?.message || 'Error request code');
    yield put(actions.requestCodeDone());
    yield cancel(); return;
  }
  yield call(() => navigate('Auth/Login', { phone: phoneNumber }));

  yield put(actions.requestCodeDone());
}
