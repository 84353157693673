import {
  type FC, useEffect, useMemo, useRef,
} from 'react';
import LottieView from 'lottie-react-native';
import { type StyleProp, type ViewStyle } from 'react-native';

import { unit } from 'components/layout';

import copied from './assets/copied.json';
import inQueue from './assets/in-queue.json';
import loading from './assets/loading.json';
import loadingDotted from './assets/loading-dotted.json';
import materialAdded from './assets/material-added.json';
import skillCompleted from './assets/skill-completed.json';
import loadingCircle from './assets/loadingCircle.json';

const Sources = {
  Copied: copied,
  InQueue: inQueue,
  Loading: loading,
  LoadingDotted: loadingDotted,
  MaterialAdded: materialAdded,
  SkillCompleted: skillCompleted,
  LoadingCircle: loadingCircle,
};

interface LoadingProps {
  width?: number,
  height?: number,
  name: keyof typeof Sources,
  loop?: boolean,
}

const Loading: FC<LoadingProps> = (props) => {
  const {
    width = 80,
    height,
    name,
    loop = false,
  } = props;

  const lottie = useRef<LottieView | null>(null);

  useEffect(() => {
    lottie.current?.play();
  }, [lottie]);

  const styles = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      width: unit(width),
    };
    if (height) {
      result.height = unit(height);
    }
    return result;
  }, [width, height]);

  return (
    <LottieView
      ref={lottie}
      style={styles}
      source={Sources[name]}
      loop={loop}
    />
  );
};

export default Loading;
