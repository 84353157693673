import { type FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { Platform, StyleSheet } from 'react-native';
import PlatformView from 'components/PlatformView';
import Lottie from 'components/Lottie';

const Preparing: FC = () => {
  const classes = useStyles();

  useEffect(() => {
    if (Platform.OS !== 'web' || typeof document === 'undefined') {
      return undefined;
    }
    document.body.classList.add(classes.hidden);
    return () => {
      document.body.classList.remove(classes.hidden);
    };
  }, [classes]);

  return (
    <PlatformView webClassName={classes.Preparing} nativeStyle={styles.Preparing}>
      <Lottie width={100} name="LoadingCircle" loop />
    </PlatformView>
  );
};

const useStyles = createUseStyles({
  Preparing: {
    position: 'fixed',
    display: 'flex',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    backgroundColor: '#ffffff',
  },
  hidden: {
    overflow: 'hidden',
  },
});

const styles = StyleSheet.create({
  Preparing: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    backgroundColor: '#111111',
  },
});

export default Preparing;
