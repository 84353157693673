import {
  takeEvery, call, put, cancel,
} from 'redux-saga/effects';

import * as api from 'services/api';
import { type InitResult } from 'services/api/credentials';

import * as actions from '../actions';

export const config = {
  action: actions.checkAuth.type,
  method: takeEvery,
};

export function* func() {
  const credentialsInit: InitResult = yield call(api.credentials.init);

  if (!credentialsInit.hasSession) {
    yield put(actions.checkAuthDone('unauthorized'));
    yield cancel(); return;
  }

  yield put(actions.checkAuthDone());
}
