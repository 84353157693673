import {
  takeEvery, call, put, cancel, delay,
  type SagaReturnType,
} from 'redux-saga/effects';

import * as api from 'services/api';

import type { Result } from 'services/api/types';
import type { ProfileEntity } from 'services/api/user/types';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: [actions.loadProfile.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadProfile>) {
  const { payload } = action;
  const { login } = payload;

  const profile: Result<ProfileEntity> = yield call(() => api.resource.user.profile());

  if (profile.error || !profile.data) {
    Alert.error(profile.error?.message || 'Server error #24');
    yield put(actions.loadProfileDone(login));
    yield cancel(); return;
  }

  yield put(actions.setProfile(profile.data));
  yield delay(10);
  yield put(actions.loadProfileDone(login));
}
