import { eventChannel } from 'redux-saga';
import {
  takeEvery, call, take,
} from 'redux-saga/effects';

import { navigate } from 'navigation/methods';
import * as api from 'services/api';

import * as actions from '../actions';

export const config = {
  action: actions.startCredentialListening.type,
  method: takeEvery,
};

function credentialEvents() {
  return eventChannel((emitter) => {
    const refreshTokenExpiredListener = api.credentials.on('REFRESH_TOKEN_EXPIRED', () => {
      emitter('ERROR_REFRESH_TOKEN_EXPIRED');
    });
    const updateRequestErrorListener = api.credentials.on('UPDATE_REQUEST_ERROR', () => {
      emitter('UPDATE_REQUEST_ERROR');
    });
    return () => {
      refreshTokenExpiredListener.off();
      updateRequestErrorListener.off();
    };
  });
}

export function* func() {
  const chan: string = yield call(credentialEvents);
  try {
    while (true) {
      const eventName: string = yield take(chan);
      if (['ERROR_REFRESH_TOKEN_EXPIRED', 'UPDATE_REQUEST_ERROR'].includes(eventName)) {
        yield call(() => navigate('Auth/RequestCode'));
      }
    }
  } finally {
    console.log('credentialListener terminated');
  }
}
