import request from 'services/api/request';
import type { Result } from 'services/api/types';

import type { UserTask } from 'services/api/task/types';

export default async (
  taskId: number,
): Promise<Result<UserTask>> => {
  const { data, error } = await request.post<UserTask>(`user/task/${taskId}/submit`);
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data,
    error,
  };
};
