import {
  type SagaReturnType,
  takeEvery, call, put, cancel, delay,
} from 'redux-saga/effects';

import { navigate, getRoute } from 'navigation/methods';

import * as api from 'services/api';

import * as actions from '../actions';

export const config = {
  action: actions.route.type,
  method: takeEvery,
};

export function* func() {
  const route: SagaReturnType<typeof getRoute> = yield call(getRoute);

  const isIndex = route?.isScreen('Index');
  const isRequestCodeScreen = route?.isScreen('Auth/RequestCode');
  const isLoginScreen = route?.isScreen('Auth/Login');
  const hasToken = api.credentials.getAccess();

  if (!hasToken && isLoginScreen) {
    yield put(actions.routed());
    yield cancel(); return;
  }

  if (!hasToken) {
    yield call(() => navigate('Auth/RequestCode'));
    yield delay(10);
    yield put(actions.routed());
    yield cancel(); return;
  }

  if (isIndex || isRequestCodeScreen || isLoginScreen) {
    yield call(() => navigate('Home'));
    yield delay(10);
    yield put(actions.routed());
    yield cancel(); return;
  }

  yield put(actions.routed());
}
