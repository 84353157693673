import {
  takeEvery, put, take,
} from 'redux-saga/effects';

import * as api from 'services/api';

import * as authStore from 'store/nodes/auth';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.ready.type,
  method: takeEvery,
};

export function* func() {
  yield put(authStore.actions.checkAuth());
  yield put(authStore.actions.startCredentialListening());
  yield take(authStore.actions.checkAuthDone.type);

  const hasSession = api.credentials.getAccess();
  if (hasSession) {
    yield put(userStore.actions.loadProfile());
    yield take(userStore.actions.loadProfileDone.type);
  }

  yield put(actions.route());
  yield take(actions.routed.type);

  yield put(actions.prepared());
}
