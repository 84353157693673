import {
  useCallback, useEffect, useState, memo, useMemo,
} from 'react';
import {
  StyleSheet, Image, type StyleProp, type ViewStyle, type ImageStyle,
} from 'react-native';

import { View } from 'components/Themed';
import { unit } from 'components/layout';
import Lottie from 'components/Lottie';
import Icon from 'components/Icon';

const defaultProps = {
  loadDelay: 5 as number,
  radius: 5 as number,
  aspectRatio: 5 as number,
};

type PictureProps = {
  style?: StyleProp<ViewStyle>,
  src?: string | null,
  loadDelay?: number,
  radius?: number,
  aspectRatio?: number,
} & typeof defaultProps;

const Picture = (props: PictureProps) => {
  const {
    style,
    src,
    loadDelay,
    radius,
    aspectRatio,
  } = props;

  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isEmpty, setEmpty] = useState<boolean>(false);
  const [uri, setUri] = useState<string | undefined>();
  const [size, setSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

  const handleHeaderResize = useCallback(({ nativeEvent }) => {
    setSize({
      width: nativeEvent.layout.width,
      height: nativeEvent.layout.height,
    });
  }, []);

  const handleLoadEnd = useCallback(() => {
    setLoaded(true);
    setEmpty(false);
  }, []);

  const handleLoadError = useCallback(() => {
    setLoaded(true);
    setEmpty(true);
  }, []);

  useEffect(() => {
    if (!src) {
      setUri(undefined);
      handleLoadError();
      return undefined;
    }
    setLoaded(false);
    setEmpty(false);
    const delayTimer = setTimeout(() => {
      setUri(src);
    }, loadDelay);

    return () => {
      if (delayTimer) {
        clearTimeout(delayTimer);
      }
    };
  }, [handleLoadError, loadDelay, src]);

  const styleFinal = useMemo(() => {
    const result = {
      ...StyleSheet.flatten(style),
      ...StyleSheet.flatten(styles.Poster),
    };
    result.aspectRatio = aspectRatio;
    if (!result.width) {
      result.width = '100%';
    }
    return result;
  }, [aspectRatio, style]);

  const imageStyleFinal = useMemo((): StyleProp<ImageStyle> => [styles.image, { aspectRatio, borderRadius: unit(radius) }], [aspectRatio, radius]);
  const emptyStyleFinal = useMemo((): StyleProp<ViewStyle> => [styles.empty, { borderRadius: unit(radius) }], [radius]);
  const loadingStyleFinal = useMemo((): StyleProp<ViewStyle> => [styles.loading, { borderRadius: unit(radius) }], [radius]);

  const renderLoader = useMemo(() => {
    if (isLoaded) {
      return null;
    }
    if (!size.width) {
      return (
        <View style={loadingStyleFinal} colorName="variant3" />
      );
    }
    const width = size.width > 80 ? 80 * 0.8 : size.width * 0.8;
    return (
      <View style={loadingStyleFinal} colorName="variant5">
        <Lottie name="LoadingCircle" width={width} loop />
      </View>
    );
  }, [isLoaded, size.width]);

  const renderEmpty = useMemo(() => {
    if (!isEmpty) {
      return null;
    }
    if (!size.width) {
      return null;
    }
    let width = size.width * 0.8;
    if (size.width > 80) {
      width = 80 * 0.8;
    }
    if (size.width < 28) {
      width = 28 * 0.8;
    }
    return (
      <View style={emptyStyleFinal} colorName="variant3">
        <Icon name="ImageOutline28" size={width} colorName="variant3" />
      </View>
    );
  }, [isEmpty, size.width]);

  return (
    <View style={styleFinal} onLayout={handleHeaderResize}>
      <Image
        onLoad={handleLoadEnd}
        onError={handleLoadError}
        source={{ uri }}
        resizeMode="cover"
        style={imageStyleFinal}
      />
      {renderLoader}
      {renderEmpty}
    </View>
  );
};

Picture.defaultProps = defaultProps;

const styles = StyleSheet.create({
  Poster: {
    position: 'relative',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    overflow: 'hidden',
  },
  empty: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  loading: {
    position: 'absolute',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});

export default memo(Picture);
