import { useEffect, useState } from 'react';
import * as Font from 'expo-font';

const useCachedResources = (): boolean => {
  const [isLoadingComplete, setLoadingComplete] = useState<boolean>(false);
  useEffect(() => {
    // Load fonts
    Font.loadAsync({
      'roboto-medium': require('../../assets/fonts/roboto-medium.otf'),
      'roboto-regular': require('../../assets/fonts/roboto-regular.otf'),
      'roboto-bold': require('../../assets/fonts/roboto-bold.otf'),
    }).then(() => { setLoadingComplete(true); });
  }, []);

  return isLoadingComplete;
};

export default useCachedResources;
