import {
  type FC,
} from 'react';
import {
  Platform,
  View,
  type ViewStyle,
} from 'react-native';

interface PlatformViewProps {
  webClassName: string,
  nativeStyle: ViewStyle,
}

const PlatformView: FC<PlatformViewProps> = (props) => {
  const {
    children,
    webClassName,
    nativeStyle,
  } = props;

  if (Platform.OS === 'web') {
    return <div className={webClassName}>{children}</div>;
  }
  return <View style={nativeStyle}>{children}</View>;
};

export default PlatformView;
