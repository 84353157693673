import {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import { useSelector } from 'react-redux';
import { type StackNavigationOptions } from '@react-navigation/stack';

import EmptyTaskPage from 'screens/Home/elements/EmptyTaskPage';

import { unit } from 'components/layout';
import { useDispatcher } from 'store/utils/redux/hooks';
import * as appStore from 'store/nodes/app';
import * as userTaskStore from 'store/nodes/userTask';
import PageHeader from 'components/PageHeader';

import { useRoute, useFocusEffect, useNavigate } from 'navigation/hooks';
import StatusFilter from './elements/StatusFilter';
import UserTasks from './elements/UserTasks';
import UserTask from './elements/UserTask';

const Home = () => {
  const dispatcher = useDispatcher();
  const route = useRoute<'Home'>();

  const [userTaskId, setUserUserTaskId] = useState<number | null>(null);
  const [userTaskStatus, setUserUserTaskStatus] = useState<'waiting_for_review' | 'to_do' | 'approved'>('to_do');

  const isRouted = useSelector(appStore.selectors.isRouted);
  const listSequence = useSelector(userTaskStore.selectors.listSequence(userTaskStatus));
  const listMeta = useSelector(userTaskStore.selectors.listMeta);

  useFocusEffect(useCallback(() => {
    if (isRouted && !listMeta.isLoaded && !listMeta.isLoading) {
      dispatcher.userTask.loadList();
    }
  }, [isRouted, listMeta]));

  useEffect(() => {
    if (listMeta.isLoaded && !userTaskId && listSequence.length > 0) {
      setUserUserTaskId(listSequence[0].id);
    }
  }, [listSequence, listMeta.isLoaded, userTaskId]);

  useEffect(() => {
    if (listSequence.length === 0) {
      return;
    }
    setUserUserTaskId(listSequence[0].id);
  }, [userTaskStatus]);

  useEffect(() => {
    if (listSequence.length === 0) {
      return;
    }
    setUserUserTaskId(listSequence[0].id);
  }, [listSequence.length]);

  return (
    <>
      <PageHeader />
      <View style={styles.Home}>
        <StatusFilter value={userTaskStatus} onSelect={setUserUserTaskStatus} />
        {listSequence.length > 0 && (
          <UserTasks
            status={userTaskStatus}
            sequence={listSequence}
            onSelect={setUserUserTaskId}
            selectedId={userTaskId}
            style={styles.userTasks}
          />
        )}
        {!!userTaskId && listSequence.length > 0 && <UserTask status={userTaskStatus} idTask={userTaskId} style={styles.userTask} />}
        {listSequence.length === 0 && <EmptyTaskPage status={userTaskStatus} />}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  Home: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: unit(32),
    paddingHorizontal: unit(16),
    backgroundColor: 'white',
  },
  userTasks: {
    width: unit(300),
    height: '100%',
  },
  userTask: {
    flex: 1,
  },
});

export default memo(Home);
