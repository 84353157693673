import {
  type PropsWithChildren, type ReactElement,
  useCallback, memo,
} from 'react';
import { type StyleProp, type GestureResponderEvent, TouchableOpacity } from 'react-native';
import type { ViewStyle } from 'react-native';

import { type ColorVariants } from 'constants/Colors';
import {
  StyleSheet, unit,
} from 'components/layout';
import Lottie from 'components/Lottie';
import { View } from 'components/Themed';

import { getActiveOpacity, getRegularOpacity, getButtonStyles } from './utils';

const defaultProps = {
  type: 'button' as 'button' | 'button-icon',
  colorName: 'variant4',
  height: 40,
  radius: 'half' as 'none' | 'half' | 'full',
  variant: 'contained',
  isDisabled: false,
  isLoading: false,
};

type ButtonProps = {
  style?: StyleProp<ViewStyle>,
  type?: 'button' | 'button-icon',
  colorName?: ColorVariants,
  width?: 'full' | number,
  height?: number,
  radius?: 'none' | 'half' | 'full',
  variant?: 'contained' | 'outlined' | 'text',
  startIcon?: ReactElement,
  endIcon?: ReactElement,
  context?: Record<string, any>,
  isDisabled?: boolean,
  isLoading?: boolean,
  onPress?: (event: GestureResponderEvent, context?: Record<string, any>) => void,
} & typeof defaultProps;

const Button = (props: PropsWithChildren<ButtonProps & typeof defaultProps>): ReactElement => {
  const {
    style,
    type,
    children,
    colorName,
    width,
    height,
    radius,
    variant,
    startIcon,
    endIcon,
    context,
    isDisabled,
    isLoading,
    onPress,
  } = props;

  const handlePress = useCallback((event) => {
    if (isDisabled) {
      return;
    }
    onPress?.(event, context);
  }, [onPress, isDisabled, context]);

  const buttonStyles = getButtonStyles(type, colorName, radius, variant, width, height);

  return (
    <TouchableOpacity
      onPress={!isLoading ? handlePress : undefined}
      activeOpacity={getActiveOpacity(isLoading, isDisabled)}
      style={[
        buttonStyles,
        style,
        { opacity: getRegularOpacity(isLoading, isDisabled) },
      ]}
    >
      <View style={{ position: 'absolute' }}>{isLoading && <Lottie name="Loading" loop />}</View>
      {Boolean(startIcon) && <View style={styles.startIcon}>{startIcon}</View>}
      {children}
      {Boolean(endIcon) && <View style={styles.endIcon}>{endIcon}</View>}
    </TouchableOpacity>
  );
};

Button.defaultProps = defaultProps;

const styles = StyleSheet.create({
  startIcon: {
    paddingRight: unit(10),
  },
  endIcon: {
    paddingLeft: unit(10),
  },
});

export default memo(Button);
