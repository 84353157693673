import {
  takeEvery, call, put, cancel, all,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';

import * as api from 'services/api';
import type { UserTask } from 'services/api/task/types';

import { select } from 'store/utils/saga/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.remove.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.remove>) {
  const { id } = action.payload;

  const userTask: UserTask | null = yield select(selectors.getById(id));
  if (!userTask) {
    yield put(actions.removeDone(id));
    yield cancel(); return;
  }

  yield put(actions.setStatus(id, 'to_do'));

  const response: SagaReturnType<typeof api.resource.task.remove> = yield call(() => api.resource.task.remove(id));
  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error 12');
    yield put(actions.setStatus(id, userTask.status));
    yield put(actions.removeDone(id));
    yield cancel(); return;
  }
  yield put(actions.removeDone(id));
}
