import {
  takeEvery, call, put, cancel, all,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';

import * as api from 'services/api';
import type { Document } from 'services/api/task/document/types';
import type { UserTask } from 'services/api/task/types';

import { select } from 'store/utils/saga/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.removeDocument.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.removeDocument>) {
  const { id, userTaskId } = action.payload;

  const userTask: UserTask | null = yield select(selectors.getById(userTaskId));
  const document: Document | undefined = userTask?.documents.find((item) => item.id === id);
  if (!document) {
    yield cancel(); return;
  }

  const oldDocuments: Document[] = [...(userTask?.documents || [])];
  const newDocuments: Document[] = userTask?.documents.filter((item) => item.id !== id) || [];

  yield put(actions.updateDocuments(userTaskId, newDocuments));

  const response: SagaReturnType<typeof api.resource.task.document.remove> = yield call(() => api.resource.task.document.remove(id, userTaskId));
  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error 12');
    yield put(actions.updateDocuments(userTaskId, oldDocuments));
    yield put(actions.removeDocumentDone(id, userTaskId));
    yield cancel(); return;
  }

  yield put(actions.removeDocumentDone(id, userTaskId));
}
