import { BACKEND_URI } from '@env';
import qs from 'query-string';

import credentials from './credentials';
import type { Result } from './types';

interface ServerResponse {
  data: Record<string, any> | null,
  error: string | null,
}

type Method = 'get' | 'post';

interface Options {
  method: Method,
  headers: Partial<{
    'Content-Type': 'application/json',
    'Authorization': string,
  }>,
  body?: string,
}

interface Params {
  query?: Record<string, any>,
  body?: Record<string, any>,
  useCredentials?: boolean,
}

const responseCodes: Record<number, string> = {
  100: 'Continue',
  101: 'Switching Protocol',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choice',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
};

const request = async <ResponseStructure = Record<string, any>>(
  method: Method,
  endpoint: string,
  params?: Params,
): Promise<Result<ResponseStructure>> => {
  const accessToken = credentials.getAccess();
  let url = `${BACKEND_URI}api/${endpoint.replace(/^\//, '')}`;
  const { query = {}, useCredentials = true } = params || {};

  if (query) {
    const queryString = qs.stringify(query);
    if (queryString) {
      url += `?${queryString}`;
    }
  }

  const options: Options = {
    method,
    headers: {},
  };
  if (method !== 'get') {
    options.headers['Content-Type'] = 'application/json';
  }
  if (method !== 'get' && params?.body) {
    options.body = JSON.stringify(params.body);
  }
  if (accessToken) {
    options.headers.Authorization = accessToken;
  }

  let response: ServerResponse | null = null;
  try {
    const result = await fetch(url, options);
    const buffer: string = await result.text();
    if (buffer.length > 0) {
      response = JSON.parse(buffer);
    }
    if (buffer.length === 0 && [200, 201].includes(result.status)) {
      response = {
        data: null,
        error: null,
      };
    }
    if (buffer.length === 0 && ![200, 201].includes(result.status)) {
      response = {
        data: null,
        error: responseCodes[result.status],
      };
    }
  } catch (error) {
    response = {
      data: null,
      error: (error as Error).message,
    };
  }

  if (response && !('error' in response) && !('data' in response)) {
    response = {
      data: response,
      error: null,
    };
  }

  const { error, data = {} } = response || {};

  if (error) {
    return {
      data: null,
      error: new Error(error),
    };
  }

  if (response && 'nextUrl' in response) {
    return {
      data: {
        items: data as ResponseStructure,
        nextUrl: (response as any).nextUrl,
      } as any,
      error: null,
    };
  }

  return {
    data: data as ResponseStructure,
    error: null,
  };
};

const methodGet = async <ResponseStructure = Record<string, any>>(
  endpoint: string,
  params?: Params,
): Promise<Result<ResponseStructure>> => request<ResponseStructure>('get', endpoint, params);

const methodPost = async <ResponseStructure = Record<string, any>>(
  endpoint: string,
  params?: Params,
): Promise<Result<ResponseStructure>> => request<ResponseStructure>('post', endpoint, params);

export default {
  get: methodGet,
  post: methodPost,
};
