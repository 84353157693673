import { Dimensions, Platform } from 'react-native';

const { width, height } = Dimensions.get('window');

/**
 * Return adaptive value based by screen width
 * @param size source value
 */
export const x = (size?: number): number | undefined => {
  if (typeof size !== 'number') {
    return undefined;
  }
  if (Platform.OS === 'web') {
    return `${size / 16}rem` as unknown as number;
  }
  return size;// ((size * width) / 375);
};

/**
 * Return adaptive value based by screen height
 * @param size source value
 */
export const y = (size?: number): number | undefined => {
  if (typeof size !== 'number') {
    return undefined;
  }
  if (Platform.OS === 'web') {
    return `${size / 16}rem` as unknown as number;
  }
  return size;// ((size * height) / 812);
};

export default x;
