import { createReducer } from '@reduxjs/toolkit';

import type { UserTaskStore } from './types';
import * as actions from './actions';

const initialState: UserTaskStore = {
  data: {},
  list: {
    sequence: [],
    map: {},
  },
  meta: {
    isLoaded: false,
    isLoading: false,
    updatingMap: {},
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.loadList, (state) => {
    state.meta.isLoaded = false;
    state.meta.isLoading = true;
  })
  .addCase(actions.loadListDone, (state) => {
    state.meta.isLoaded = true;
    state.meta.isLoading = false;
  })
  .addCase(actions.setItems, (state, action) => {
    const { items } = action.payload;
    if (!items || !Array.isArray(items) || items.length === 0) {
      return;
    }
    items.forEach((item) => {
      state.data[item.id] = item;
      if (item.id in state.list.map) {
        return;
      }
      state.list.sequence.push({
        id: item.id,
        status: item.status,
      });
      state.list.map[item.id] = state.list.sequence.length - 1;
    });
    state.meta.isLoaded = true;
    state.meta.isLoading = false;
  })
  .addCase(actions.setStatus, (state, action) => {
    const { payload } = action;
    if (!state.data[payload.id]) {
      return;
    }
    state.list.map = {};
    state.list.sequence = state.list.sequence.map((item, key) => {
      state.list.map[item.id] = key;
      return {
        id: item.id,
        status: item.id === payload.id ? payload.status : item.status,
      };
    });
    state.data[payload.id].status = payload.status;
  })
  .addCase(actions.submit, (state, action) => {
    state.meta.updatingMap[action.payload.id] = true;
  })
  .addCase(actions.submitDone, (state, action) => {
    if (state.meta.updatingMap[action.payload.id]) {
      delete state.meta.updatingMap[action.payload.id];
    }
  })
  .addCase(actions.remove, (state, action) => {
    state.meta.updatingMap[action.payload.id] = true;
  })
  .addCase(actions.removeDone, (state, action) => {
    if (state.meta.updatingMap[action.payload.id]) {
      delete state.meta.updatingMap[action.payload.id];
    }
  })
  .addCase(actions.removeDocument, (state, action) => {
    state.meta.updatingMap[action.payload.id] = true;
  })
  .addCase(actions.removeDocumentDone, (state, action) => {
    if (state.meta.updatingMap[action.payload.id]) {
      delete state.meta.updatingMap[action.payload.id];
    }
  })
  .addCase(actions.updateDocuments, (state, action) => {
    const { payload } = action;
    if (!state.data[payload.userTaskId]) {
      return;
    }
    state.data[payload.userTaskId].documents = payload.documents;
  }));
