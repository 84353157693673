import {
  type ReactElement, useCallback,
} from 'react';
import Modal from 'react-native-modal';
import { StyleSheet } from 'react-native';

import { useSelector } from 'store/utils/redux/hooks';

import useThemeColor from 'hooks/useThemeColor';

import { Text, View } from 'components/Themed';
import { unit } from 'components/layout';
import Button from 'components/Button';
import BlurView from 'components/BlurView';

import controller from './classes/Controller';

export const Dialogs = (): ReactElement => {
  const dialogs = useSelector((state) => state.app.dialogs);

  const backDropBackground = useThemeColor<string>({ dark: '#FFFFFF44', light: '#00000044' }, 'view');

  const handlePress = useCallback((event, context) => {
    const { dialogId, handleId } = context;
    const handler = controller.getHandler(dialogId, handleId);
    if (!handler) {
      return;
    }
    handler(dialogId);
  }, []);

  return (
    <>
      {dialogs.map((dialog) => (
        <Modal
          key={dialog.id}
          isVisible={dialog.isVisible}
          style={styles.Dialog}
          animationIn="bounceIn"
          animationInTiming={400}
          animationOut="fadeOut"
          animationOutTiming={300}
          swipeDirection={[]}
          customBackdrop={<BlurView style={[styles.blur, { backgroundColor: backDropBackground }]} factor={3} />}
          backdropOpacity={1}
        >
          <View colorName="variant6" style={styles.container}>
            <Text size={22} style={styles.title}>{dialog.body.title}</Text>
            {!!dialog.body.text && <Text size={15} style={styles.text} colorName="text">{dialog.body.text}</Text>}
            <View style={styles.buttons}>
              {Array.isArray(dialog.buttons) && dialog.buttons.map((button) => (
                <Button
                  key={button.label}
                  variant={button.variant || 'contained'}
                  colorName={button.colorName || 'variant4'}
                  onPress={handlePress}
                  height={40}
                  context={{ dialogId: dialog.id, handleId: button.handleId }}
                  style={styles.button}
                >
                  <Text size={15} colorName={button.textColorName || 'text'}>{button.label}</Text>
                </Button>
              ))}
            </View>
          </View>
        </Modal>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  Dialog: {
  },
  blur: {
    flex: 1,
  },
  container: {
    paddingHorizontal: unit(32),
    paddingTop: unit(24),
    paddingBottom: unit(24),
    borderRadius: unit(16),
    alignSelf: 'center',
  },
  buttons: {
    flexDirection: 'row',
    paddingTop: unit(24),
    marginHorizontal: unit(-6),
  },
  title: {
    paddingTop: unit(4),
  },
  text: {
    paddingTop: unit(4),
  },
  button: {
    flex: 1,
    marginHorizontal: unit(6),
  },
});

export default controller;
