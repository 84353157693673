import request from 'services/api/request';
import type { Result } from 'services/api/types';

import type { UserTask } from 'services/api/task/types';

export default async (): Promise<Result<UserTask[]>> => {
  const { data, error } = await request.get<UserTask[]>('/user/tasks');
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }
  return {
    data,
    error,
  };
};
