import {
  memo, useCallback, useMemo,
} from 'react';
import {
  type StyleProp, type ViewStyle, TouchableOpacity, StyleSheet,
} from 'react-native';

import { useSelector } from 'react-redux';

import useThemeColor from 'hooks/useThemeColor';

import * as userStore from 'store/nodes/user';

import { Text, View } from 'components/Themed';
import { unit } from 'components/layout';

import Icon from 'components/Icon';
import { useDispatcher } from 'store/utils/redux/hooks';

import LanguagePicker from 'components/LanguagePicker';
import Picture from 'components/Picture';
import { useTranslation } from 'translations';

export const getAvatarText = (name = ''): string | null => {
  const preparedName = name?.toUpperCase().replace(/\s\s+/g, ' ').trim();
  const [firstWord, secondWord] = preparedName.split(' ');
  if (!firstWord) {
    return null;
  }
  if (!secondWord) {
    return firstWord.slice(0, 2);
  }
  return (`${firstWord[0]}${secondWord[0]}`);
};

const PageHeader = () => {
  const data = useSelector(userStore.selectors.getMy);
  const isLoaded = useSelector(userStore.selectors.isLoaded('my'));
  const dispatch = useDispatcher();

  const handleSignOut = useCallback(() => {
    dispatch.auth.logout();
  }, []);

  const borderColor = useThemeColor<string>({}, 'variant4');

  const styleFinal = useMemo((): StyleProp<ViewStyle> => ({
    ...StyleSheet.flatten(styles.PageHeader),
    borderColor,
    direction: 'ltr',
  }), [borderColor]);

  return (
    <View style={styleFinal}>
      <View style={styles.content}>
        {isLoaded && (
          <>
            <View style={styles.companyName}>
              {!!data.company?.companyLogoUrl && <Picture src={data.company?.companyLogoUrl} style={styles.companyLogo} />}
              <View style={styles.companyText}>
                <Text style={{ direction: 'ltr' }} colorName="text" size={16}>DocsToDo</Text>
                <Text colorName="variant4" size={12}>{data.company?.companyName}</Text>
              </View>
            </View>
            <View style={styles.user}>
              <LanguagePicker />
              <View style={styles.avatar}>
                <View style={styles.avatarIcon}>
                  <Text colorName="text" size={12}>{getAvatarText(`${data.firstName} ${data.lastName}`)}</Text>
                </View>
              </View>
              <View style={styles.profile}>
                <Text size={12}>{`${data.firstName} ${data.lastName}`}</Text>
                <Text style={{ direction: 'ltr' }} colorName="variant4" size={12}>{data.phone}</Text>
              </View>
              <TouchableOpacity onPress={handleSignOut}>
                <Icon name="LogoutIcon" colorName="variant4" size={32} direction="ltr" />
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  PageHeader: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderBottomWidth: unit(1),
    height: unit(64),
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    paddingHorizontal: unit(16),
  },
  avatar: {
    width: unit(42),
    alignSelf: 'center',
  },
  avatarIcon: {
    width: unit(34),
    height: unit(34),
    borderRadius: unit(17),
    borderWidth: unit(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  profile: {
    marginRight: unit(12),
    marginLeft: unit(12),
  },
  companyName: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  companyLogo: {
    marginRight: unit(12),
    width: unit(48),
    height: unit(48),
  },
  companyText: {
  },
  user: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
});

export default memo(PageHeader);
