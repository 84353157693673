import type { ProfileEntity } from 'services/api/user/types';
import type { State } from 'store/types';

export const getMy = (state: State): ProfileEntity => state.user.data.my as ProfileEntity;

export const isLoading = (login?: string | 'my') => (state: State) => {
  if (!login) {
    return false;
  }
  return state.user.meta.loadingMap[login] || false;
};

export const isLoaded = (login?: string | 'my') => (state: State) => {
  if (!login) {
    return false;
  }
  return !isLoading(login)(state) && !!state.user.data[login];
};
