import { useStore } from 'react-redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { DEV_IP } from '@env';

import devToolsEnhancer from './devToolsEnhancer';

import * as app from './nodes/app';
import * as auth from './nodes/auth';
import * as user from './nodes/user';
import * as userTask from './nodes/userTask';

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  function* sagas() {
    yield all([
      app.sagas(),
      auth.sagas(),
      user.sagas(),
      userTask.sagas(),
    ]);
  }

  const enhancers = !DEV_IP ? [] : [devToolsEnhancer({
    hostname: DEV_IP,
    port: 8000,
    realtime: true,
  })];

  const store = ({
    ...configureStore({
      reducer: {
        app: app.reducer,
        auth: auth.reducer,
        user: user.reducer,
        userTask: userTask.reducer,
      },
      middleware: [...getDefaultMiddleware(), sagaMiddleware],
      devTools: false,
      enhancers,
    }),
    saga: {
      run: sagaMiddleware.run,
    },
  });

  store.saga.run(sagas);

  store.dispatch({ type: 'STORE/READY' });

  return store;
};

const store = createStore();

export {
  useStore,
  store,
};
