import { Platform, type StyleProp, type ViewStyle } from 'react-native';
import { type CSSProperties } from 'react';

import { type ColorVariants } from 'constants/Colors';
import { unit } from 'components/layout';
import useThemeColor from 'hooks/useThemeColor';

export const getActiveOpacity = (isLoading: boolean, isDisabled: boolean): number => {
  if (isLoading) {
    return 0.8;
  }
  if (isDisabled) {
    return 0.5;
  }
  return 0.8;
};

export const getRegularOpacity = (isLoading: boolean, isDisabled: boolean): number => {
  if (isLoading) {
    return 0.8;
  }
  if (isDisabled) {
    return 0.5;
  }
  return 1;
};

export const getButtonStyles = (
  type: 'button' | 'button-icon',
  colorName: ColorVariants,
  radius: 'none' | 'half' | 'full',
  variant: 'contained' | 'outlined' | 'text',
  width?: 'full' | number,
  height = 40,
): StyleProp<ViewStyle> => {
  const radiusMap = {
    none: 0,
    half: height / 40 * 8,
    full: height / 2,
  };

  const styles: StyleProp<ViewStyle> = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: unit(height),
    borderRadius: unit(radiusMap[radius]),
    paddingHorizontal: type === 'button-icon' ? undefined : unit(height / 2),
    overflow: 'hidden',
  };

  if (width === 'full') {
    styles.width = '100%';
  } else
  if (typeof width === 'string') {
    styles.width = width;
  } else
  if (typeof width === 'number') {
    styles.width = unit(width);
  } else
  if (Platform.OS === 'web') {
    (styles as CSSProperties).width = 'fit-content';
  } else {
    // need to add native implementation
  }

  const color = useThemeColor<string>({}, colorName);

  if (variant === 'contained') {
    styles.backgroundColor = color;
    styles.borderWidth = 1;
    styles.borderStyle = 'solid';
    styles.borderColor = color;
  }
  if (variant === 'outlined') {
    styles.borderWidth = 1;
    styles.borderStyle = 'solid';
    styles.borderColor = color;
  }

  return styles;
};
