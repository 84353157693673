import {
  type PropsWithChildren, type ReactElement,
  useCallback,
} from 'react';
import {
  StyleSheet, TouchableOpacity,
} from 'react-native';
import * as ExpoDocumentPicker from 'expo-document-picker';

type DocumentPickerProps = {
  acceptedTypes?: string,
  multiple?: boolean,
  onChange?: (result: any) => void,
};

const DocumentPicker = (props: PropsWithChildren<DocumentPickerProps>): ReactElement => {
  const {
    acceptedTypes,
    multiple,
    children,
    onChange,
  } = props;

  const handlePick = useCallback(async () => {
    const result = await ExpoDocumentPicker.getDocumentAsync({
      type: acceptedTypes,
      multiple,
    });
    onChange?.(result);
  }, [onChange, acceptedTypes, multiple]);

  return (
    <TouchableOpacity style={styles.DocumentPicker} onPress={handlePick} activeOpacity={0.8}>
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  DocumentPicker: {
  },
});

export default DocumentPicker;
