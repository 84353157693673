import Colors from 'constants/Colors';
import useColorScheme from 'hooks/useColorScheme';

interface Props<T> {
  light?: T,
  dark?: T,
}

type ColorName = keyof typeof Colors.light & keyof typeof Colors.dark;

const useThemeColor = <T = string | string[]>(
  props: Props<T>,
  colorName: ColorName,
): T => {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  }

  return Colors[theme][colorName] as unknown as T;
};

export default useThemeColor;
