import { Platform } from 'react-native';

export default (val?: number): number | undefined => {
  if (typeof val !== 'number') {
    return undefined;
  }
  if (Platform.OS === 'web') {
    return `${val / 16}rem` as unknown as number;
  }
  return val;
};
