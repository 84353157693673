import {
  takeEvery, call, put, cancel, all,
  type SagaReturnType,
} from 'redux-saga/effects';

import Alert from 'components/Alert';
import * as api from 'services/api';

import * as actions from '../actions';

export const config = {
  action: actions.loadList.type,
  method: takeEvery,
};

export function* func() {
  const response: SagaReturnType<typeof api.resource.task.list> = yield call(() => api.resource.task.list());
  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error 12');
    yield put(actions.loadListDone());
    yield cancel();
  }
  yield put(actions.setItems(response.data));
  yield put(actions.loadListDone());
}
