import auth from './auth/route';
import task from './task/route';
import user from './user/route';
import credentials from './credentials';

const resource = {
  auth,
  task,
  user,
};

export {
  resource,
  credentials,
};
