import { useMemo } from 'react';
import { useDispatch, useSelector as useSelectorOrigin } from 'react-redux';
import { store } from 'store';

import * as app from '../../nodes/app/actions';
import * as auth from '../../nodes/auth/actions';
import * as user from '../../nodes/user/actions';
import * as userTask from '../../nodes/userTask/actions';

import { type State } from '../../types';

export const useSelector = <Selected>(
  selector: (state: State) => Selected,
  equalityFn?: (left: Selected, right: Selected) => boolean,
) => useSelectorOrigin<State, Selected>(selector, equalityFn);

const handler = {
  get(target: any, propertyName: PropertyKey): any {
    return (...args: any[]) => {
      const action = target[propertyName].apply(null, args);
      store.dispatch(action);
    };
  },
};

const withHandler = <U extends {[key: string]: Record<string, any>}>(actionsMap: U): U => {
  const result = {} as {[key: string]: Record<string, any>};
  Object.keys(actionsMap).forEach((name) => {
    result[name] = new Proxy(actionsMap[name], handler);
  });
  return result as typeof actionsMap;
};

export const dispatcher = withHandler({
  app,
  auth,
  user,
  userTask,
});

export const useDispatcher = () => {
  return useMemo(() => dispatcher, []);
};

export {
  useDispatch,
};
