export default {
  light: {
    text: '#2B2F36',
    variant2: '#606B7C',
    variant3: '#484848',
    variant4: '#2D67BD',
    variant5: '#FFFFFF',
    variant6: '#F7FAFF',
    variant7: '#D9E1ED',
    variant8: '#e8e8e8',
    error: '#BF2222',
    view: 'transparent',
    transparent: 'transparent',
    background: '#ffffff',
    statusIcon: '#3667B2',
  },
  dark: {
    text: '#2B2F36',
    variant2: '#606B7C',
    variant3: '#484848',
    variant4: '#2D67BD',
    variant5: '#FFFFFF',
    variant6: '#F7FAFF',
    variant7: '#D9E1ED',
    variant8: '#e8e8e8',
    error: '#BF2222',
    view: 'transparent',
    transparent: 'transparent',
    background: '#ffffff',
    statusIcon: '#3667B2',
  },
};

export type ColorVariants =
  | 'text'
  | 'variant2'
  | 'variant3'
  | 'variant4'
  | 'variant5'
  | 'variant6'
  | 'variant7'
  | 'variant8'
  | 'error'
  | 'view'
  | 'transparent'
  | 'statusIcon'
