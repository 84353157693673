import request from 'services/api/request';
import type { SuccessResult } from 'services/api/types';

export default async (
  phoneNumber: string,
): Promise<SuccessResult> => {
  const { data, error } = await request.post('/session/request_code', {
    body: {
      phone: phoneNumber,
    },
    useCredentials: false,
  });

  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: { success: true },
    error,
  };
};
