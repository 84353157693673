import {
  memo, type PropsWithChildren, type ReactElement,
} from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { Text, View } from 'components/Themed';
import { unit } from 'utils';
import Icon, { type IconNames } from 'components/Icon';

import { useTranslation } from 'translations';
import { useColorBackground } from 'hooks/useColorBackground';
import pluralize from 'pluralize';

const defaultProps = {
  iconName: 'ErrorOutline',
};

type ItemProps = {
    id: string,
    count: number,
    onPress?: () => void,
    iconName?: string,
    status: string,
    isSelected: boolean
}& typeof defaultProps

const StatusButton = (props: PropsWithChildren<ItemProps & typeof defaultProps>): ReactElement => {
  const {
    id, count, onPress, iconName, status, isSelected,
  } = props;

  const { t } = useTranslation();

  const color = useColorBackground(status);

  return (
    <TouchableOpacity style={[styles.formToSend, { backgroundColor: color }]} onPress={onPress} activeOpacity={0.8}>
      <Icon
        colorName="statusIcon"
        name={iconName as IconNames}
        size={34}
        direction="ltr"
        style={styles.icon}
      />
      <View style={styles.text}>
        <Text colorName="text" weight={700} size={15}>{t(id)}</Text>
        <Text weight={400} size={12} colorName="variant2">
          {pluralize(t('task'), count, true)}
        </Text>
      </View>
      <View style={styles.arrowIcon}>
        { isSelected && (
          <Icon
            colorName="statusIcon"
            name="ArrowIcon"
            size={18}
          />
        ) }
      </View>
    </TouchableOpacity>
  );
};

StatusButton.defaultProps = defaultProps;

const styles = StyleSheet.create({
  Item: {
    width: unit(276),
    paddingVertical: unit(16),
    paddingLeft: unit(24),
    paddingRight: unit(18),
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  formToSend: {
    height: unit(68),
    width: unit(220),
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: unit(16),
    backgroundColor: '#EBF3FF',
    borderRadius: unit(8),
  },
  formInReview: {
    height: unit(68),
    width: unit(220),
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: unit(16),
    backgroundColor: '#F9FAE4',
    borderRadius: unit(8),
  },
  formPassed: {
    height: unit(68),
    width: unit(220),
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: unit(16),
    backgroundColor: '#E4FAE5',
    borderRadius: unit(8),
  },
  text: {
    flex: 1,
    marginLeft: unit(16),
  },
  icon: {
    paddingLeft: unit(16),
  },
  arrowIcon: {
    width: unit(24),
    height: unit(18),
    marginRight: unit(8),
  },
});

export default memo(StatusButton);
