import { type TextStyle } from 'react-native';
import { useMemo } from 'react';
import { type FontProps } from '@font';

import { unit } from 'components/layout';

export const getFontFamilyByWeight = (weight?: number): string => {
  const fontWeightMap: Record<number, string> = {
    400: 'roboto-regular',
    500: 'roboto-medium',
    700: 'roboto-bold',
  };

  if (!weight || typeof fontWeightMap[weight] === 'undefined') {
    return 'roboto-regular';
  }

  return fontWeightMap[weight];
};

export const getLineHeightByFontSize = (size: number): number => {
  const lineHeightMap: Record<number, number> = {
    10: 13,
    11: 13,
    12: 16,
    13: 18,
    15: 20,
    16: 21,
    17: 22,
    18: 22,
    20: 25,
    22: 27,
    24: 29,
    28: 34,
    34: 41,
  };
  return lineHeightMap[size] || 13;
};

const useFontProps = (props: Partial<FontProps>): TextStyle => {
  const {
    size,
    weight,
    align,
    opacity,
  } = props;

  return useMemo(() => {
    const result: TextStyle = {};
    if (size) {
      result.lineHeight = unit(getLineHeightByFontSize(size));
      result.fontSize = unit(size);
    }
    result.fontFamily = getFontFamilyByWeight(weight);
    if (align) {
      result.textAlign = align;
    }
    if (opacity) {
      result.opacity = opacity;
    }
    return result;
  }, [align, opacity, size, weight]);
};

export default useFontProps;
