import { type FC, memo, useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import moment from 'moment-timezone';

import { Text, View } from 'components/Themed';
import { unit } from 'utils';
import { useSelector } from 'react-redux';
import * as userTaskStore from 'store/nodes/userTask';
import Icon from 'components/Icon';
import { useColorBackground } from 'hooks/useColorBackground';
import { useTranslation } from 'translations';

interface ItemProps {
  id: number,
  isSelected: boolean,
  onPress?: (id: number) => void,
  status: string
}

const Item: FC<ItemProps> = (props) => {
  const {
    id, isSelected, onPress, status,
  } = props;

  const { t } = useTranslation();

  const data = useSelector(userTaskStore.selectors.getById(id));
  const dueDate = moment(data.dueDate).format('DD MMM, YYYY');
  const approved = moment(data.approvedAt).format('DD MMM, YYYY');
  const submittedAt = moment(data.submittedAt).format('DD MMM, YYYY');

  const handlePress = useCallback(() => {
    onPress?.(id);
  }, [onPress, id]);

  return (
    <TouchableOpacity onPress={handlePress} style={styles.Item} activeOpacity={0.8}>
      <View style={styles.text}>
        <View>
          <Text colorName="text" size={15}>{data.task.name}</Text>
        </View>
        <View style={{ paddingTop: unit(6), paddingBottom: unit(10) }}>
          <Text colorName="text" size={12}>{data.task.description}</Text>
        </View>
        {status === 'to_do' && (
          <View>
            <Text colorName="variant2" size={12}>{`${t('dueDate')}: ${dueDate}`}</Text>
          </View>
        )}
        {status === 'waiting_for_review' && (
          <View>
            <Text colorName="variant2" size={12}>{`${t('submittedAt')}: ${submittedAt}`}</Text>
          </View>
        )}
        {status === 'approved' && (
          <View>
            <Text colorName="variant2" size={12}>{`${t('approvedAt')}: ${approved}`}</Text>
          </View>
        )}
      </View>
      <View style={styles.icon}>
        {isSelected && <Icon colorName="statusIcon" name="ArrowIcon" size={18} />}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  Item: {
    width: unit(276),
    paddingVertical: unit(16),
    paddingLeft: unit(24),
    paddingRight: unit(18),
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  text: {
    maxWidth: unit(220),
  },
  icon: {
    alignItems: 'flex-end',
  },
});

export default memo(Item);
