import AsyncStorage from '@react-native-async-storage/async-storage';
import decode from 'jwt-decode';
import moment from 'moment-timezone';
import EventEmitter from 'events';

import * as api from 'services/api/index';

interface TokenData {
  session_id: number,
  exp: number,
  iat: number,
  type: 'ACCESS' | 'REFRESH',
}

type RefreshResult = 'REFRESH_TOKEN_NOT_EXISTS' | 'REFRESH_TOKEN_EXPIRED' | 'UPDATE_REQUEST_ERROR' | 'STILL_ACTIVE' | 'SUCCESS';

export interface InitResult {
  result: RefreshResult,
  hasSession: boolean,
  sessionId: number | null,
}

interface Pair {
  access: string,
  refresh: string,
}

const REFRESH_TOKEN_NOT_EXISTS = 'REFRESH_TOKEN_NOT_EXISTS';
const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED';
const UPDATE_REQUEST_ERROR = 'UPDATE_REQUEST_ERROR';
const STILL_ACTIVE = 'STILL_ACTIVE';
const SUCCESS = 'SUCCESS';

class Credentials {
  private accessToken?: string | null;

  private refreshToken?: string | null;

  private readonly event = new EventEmitter();

  private tickUpdate = async () => {
    const aMinute = 1000 * 60;
    const result = await this.update();

    if ([SUCCESS].includes(result) && this.accessToken && this.refreshToken) {
      await this.set(this.accessToken, this.refreshToken);
    }

    if ([REFRESH_TOKEN_EXPIRED, UPDATE_REQUEST_ERROR].includes(result)) {
      await this.clear();
    }

    setTimeout(() => { this.tickUpdate().catch((error) => console.log(error.message)); }, aMinute);

    if (result !== REFRESH_TOKEN_NOT_EXISTS) {
      this.event.emit(result);
    }
    return result;
  };

  private update = async (): Promise<RefreshResult> => {
    if (!this.refreshToken) {
      return REFRESH_TOKEN_NOT_EXISTS;
    }
    const refreshExpired = this.getMinutesLeftOfExpire(this.refreshToken) < 2;
    if (refreshExpired) {
      return REFRESH_TOKEN_EXPIRED;
    }
    const accessExpired = this.getMinutesLeftOfExpire(this.accessToken) < 2;
    if (!accessExpired) {
      return STILL_ACTIVE;
    }
    const result = await api.resource.auth.refresh(this.refreshToken);
    if (result.error || !result.data) {
      return UPDATE_REQUEST_ERROR;
    }
    this.accessToken = result.data.access;
    this.refreshToken = result.data.refresh;
    return SUCCESS;
  };

  private getTokenData = (jwt: string | null | undefined): TokenData | null => {
    if (!jwt) {
      return null;
    }
    let data: TokenData | null | undefined;
    try {
      data = decode(jwt);
    } catch (error) {
      data = null;
    }
    if (!data) {
      return null;
    }
    return data;
  };

  private getMinutesLeftOfExpire = (jwt: string | null | undefined): number => {
    if (!jwt) {
      return 0;
    }
    const data = this.getTokenData(jwt);
    if (!data) {
      return 0;
    }
    const secondsLeft = moment.utc(data.exp * 1000).diff(moment.utc()) / 1000;
    if (secondsLeft < 0) {
      return 0;
    }
    return secondsLeft / 60;
  };

  private load = async (): Promise<void> => {
    const [access, refresh] = await Promise.all([
      AsyncStorage.getItem('@access_token'),
      AsyncStorage.getItem('@refresh_token'),
    ]) as [string | undefined, string | undefined];
    this.accessToken = access;
    this.refreshToken = refresh;
  };

  public init = async (pair?: Pair): Promise<InitResult> => {
    if (pair && pair.access && pair.refresh) {
      await this.set(pair.access, pair.refresh);
    } else {
      await this.load();
    }
    const updateResult = await this.tickUpdate();
    return {
      result: updateResult,
      hasSession: [SUCCESS, STILL_ACTIVE].includes(updateResult),
      sessionId: this.sessionId(),
    };
  };

  public getAccess = () => this.accessToken;

  public getRefresh = () => this.refreshToken;

  public hasSession = () => {
    return this.getMinutesLeftOfExpire(this.accessToken) > 0 && this.getMinutesLeftOfExpire(this.refreshToken) > 0;
  };

  public sessionId = (): number | null => {
    const data = this.getTokenData(this.accessToken);
    if (!data) {
      return null;
    }
    return data.session_id;
  };

  public clear = async () => {
    this.accessToken = undefined;
    this.refreshToken = undefined;
    await Promise.all([
      AsyncStorage.removeItem('@access_token'),
      AsyncStorage.removeItem('@refresh_token'),
    ]);
  };

  public set = async (access: string, refresh?: string) => {
    this.accessToken = access;
    this.refreshToken = refresh;
    await Promise.all([
      AsyncStorage.setItem('@access_token', this.accessToken),
      refresh ? AsyncStorage.setItem('@refresh_token', refresh) : null,
    ]);
  };

  public on = (eventName: string | symbol, listener: (...args: any[]) => void) => {
    this.event.on(eventName, listener);
    return {
      off: () => this.event.off(eventName, listener),
    };
  };

  public off = (eventName: string | symbol, listener: (...args: any[]) => void): void => {
    this.event.off(eventName, listener);
  };

  public removeAllListeners = (event?: string | symbol): this => {
    this.event.removeAllListeners(event);
    return this;
  };
}

export default new Credentials();
