import request from 'services/api/request';
import type { Result } from 'services/api/types';
import credentials from 'services/api/credentials';

export interface AuthLogOutData {
  closed: boolean,
}

export default async (): Promise<Result<AuthLogOutData>> => {
  if (!credentials.getAccess()) {
    return {
      data: {
        closed: true,
      },
      error: null,
    };
  }
  await credentials.clear();
  return {
    data: {
      closed: true,
    },
    error: null,
  };
};
