import {
  takeLatest, call, put, cancel,
} from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';

import { navigate } from 'navigation/methods';

import Alert from 'components/Alert';

import * as api from 'services/api';

import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.logout.type,
  method: takeLatest,
};

export function* func() {
  const result: SagaReturnType<typeof api.resource.auth.logout> = yield call(() => api.resource.auth.logout());
  if (result?.error) {
    Alert.error(result?.error?.message || 'Server error #6');
    yield put(actions.logoutDone(result?.error));
    yield cancel(); return;
  }

  yield put(actions.logoutDone());

  yield call(() => navigate('Auth/RequestCode'));
  yield put(userStore.actions.reset());
}
