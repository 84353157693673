/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import { type FC } from 'react';
import { Text as DefaultText, View as DefaultView } from 'react-native';

import { type FontProps } from '@font';

import {
  type ColorVariants,
} from 'constants/Colors';

import useFontProps from 'hooks/useFontProps';
import useThemeColor from 'hooks/useThemeColor';
import { useTranslation } from 'translations';

type ThemeProps<T> = {
  lightColor?: string;
  darkColor?: string;
  colorName?: T,
};

export type TextProps = ThemeProps<ColorVariants> & DefaultText['props'];

export type ViewProps = ThemeProps<ColorVariants> & DefaultView['props'];

export const Text: FC<TextProps & FontProps> = (props) => {
  const {
    style,
    lightColor,
    darkColor,
    size,
    weight,
    align,
    opacity,
    colorName,
    ...otherProps
  } = props;
  const { direction } = useTranslation();
  const color = useThemeColor({
    light: lightColor, dark: darkColor,
  }, colorName || 'text');
  const config = useFontProps({
    size, weight, align, opacity,
  });

  return <DefaultText style={[{ color, direction }, config, style]} {...otherProps} />;
};

export const View: FC<ViewProps> = (props) => {
  const {
    style,
    lightColor,
    darkColor,
    colorName,
    ...otherProps
  } = props;
  useTranslation();

  const color = useThemeColor({ light: lightColor, dark: darkColor }, colorName || 'view');
  let backgroundColor: string | undefined = color;
  if (backgroundColor === 'transparent') {
    backgroundColor = undefined;
  }

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
};
