import { Platform } from 'react-native';
// eslint-disable-next-line import/no-mutable-exports
let devToolsEnhancer;

if (Platform.OS !== 'web') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  devToolsEnhancer = require('remote-redux-devtools').default;
} else {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  devToolsEnhancer = require('redux-devtools-extension/developmentOnly.js').devToolsEnhancer;
}
export default devToolsEnhancer as CallableFunction;
