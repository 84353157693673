import connect from '../../utils/saga/connect';
import * as loadList from './sagas/loadList';
import * as submit from './sagas/submit';
import * as remove from './sagas/remove';
import * as removeDocuments from './sagas/removeDocuments';
import * as addDocument from './sagas/addDocument';

export default connect([
  loadList,
  submit,
  remove,
  removeDocuments,
  addDocument,
]);
