import { createReducer } from '@reduxjs/toolkit';

import type { UserStore } from './types';
import * as actions from './actions';

const initialState: UserStore = {
  data: {},
  idMap: {},
  meta: {
    loadingMap: {},
  },
};

export default createReducer(initialState, (builder) => builder
  .addCase(actions.reset, (state) => {
    state.data = {};
    state.idMap = {};
    state.meta = {
      loadingMap: {},
    };
  })
  .addCase(actions.loadProfile, (state, action) => {
    state.meta.loadingMap[action.payload.login] = true;
  })
  .addCase(actions.loadProfileDone, (state, action) => {
    delete state.meta.loadingMap[action.payload.login];
  })
  .addCase(actions.setProfile, (state, action) => {
    if (!action.payload.data) {
      return;
    }
    if (action.payload.data.isMy) {
      state.data.my = action.payload.data;
    }
    state.data[action.payload.data.phone] = state.data.my;
    state.idMap[action.payload.data.id] = action.payload.data.phone;
  }));
