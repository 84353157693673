import type { State } from 'store/types';

export const listSequence = (status?: 'waiting_for_review' | 'to_do' | 'approved') => (state: State) => {
  if (!status) {
    return state.userTask.list.sequence;
  }
  return state.userTask.list.sequence.filter((item) => item.status === status);
};
export const listMeta = (state: State) => state.userTask.meta;

export const getById = (id: number) => (state: State) => state.userTask.data[id] || null;

export const isUpdating = (id: number) => (state: State) => Boolean(state.userTask.meta.updatingMap[id]);

export const countTask = (status?: 'waiting_for_review' | 'to_do' | 'approved') => (state: State) => {
  if (!status) {
    return 0;
  }
  return state.userTask.list.sequence.filter((item) => item.status === status).length;
};
