import { memo, useCallback, useMemo } from 'react';
import {
  type StyleProp, type ViewStyle, StyleSheet,
} from 'react-native';

import { View } from 'components/Themed';
import { unit } from 'utils';
import { useSelector } from 'react-redux';
import * as userTaskStore from 'store/nodes/userTask';

import StatusButton from './elements/StatusButton';

type UserTasksProps = {
  style?: StyleProp<ViewStyle>
  value: 'waiting_for_review' | 'to_do' | 'approved'
  onSelect?: (status: 'waiting_for_review' | 'to_do' | 'approved') => void,
}

const StatusFilter = (props: UserTasksProps) => {
  const {
    style,
    onSelect,
    value,
  } = props;

  const handlePress = useCallback(() => {
    onSelect?.('to_do');
  }, [onSelect]);

  const handlePressToDo = useCallback(() => {
    onSelect?.('waiting_for_review');
  }, [onSelect]);

  const handlePressApproved = useCallback(() => {
    onSelect?.('approved');
  }, [onSelect]);

  const countTaskToDo = useSelector(userTaskStore.selectors.countTask('to_do'));
  const countTaskWaiting = useSelector(userTaskStore.selectors.countTask('waiting_for_review'));
  const countTaskApproved = useSelector(userTaskStore.selectors.countTask('approved'));

  const buttons = useMemo(() => [
    {
      id: 'docsToSend',
      count: countTaskToDo,
      iconName: 'DocsSendIcon',
      onPress: handlePress,
      status: 'to_do',
    },
    {
      id: 'docsInReview',
      count: countTaskWaiting,
      iconName: 'DocsInReviewIcon',
      onPress: handlePressToDo,
      status: 'waiting_for_review',
    },
    {
      id: 'passedReview',
      count: countTaskApproved,
      iconName: 'PassedReviewIcon',
      onPress: handlePressApproved,
      status: 'approved',
    },
  ], [countTaskToDo, countTaskWaiting, countTaskApproved, handlePress, handlePressToDo, handlePressApproved]);

  return (
    <View style={[style, styles.StatusFilter]}>
      {buttons.map((values) => {
        const {
          id, count, iconName, onPress, status,
        } = values;
        return (
          <StatusButton
            iconName={iconName}
            status={status}
            isSelected={value === status}
            id={id}
            key={id}
            count={count}
            onPress={onPress}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  StatusFilter: {
    marginRight: unit(16),
  },
});

export default memo(StatusFilter);
