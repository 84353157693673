import * as uuid from 'uuid';

import { dispatcher } from 'store/utils/redux/hooks';
import type { ButtonAlias } from 'store/nodes/app/types';
import type { Body, Button } from 'components/Dialog/types';

class Controller {
  private readonly callbacks: Record<string, Record<string, (id: string) => void>>;

  constructor() {
    this.callbacks = {};
  }

  public show = (body: Body, buttons: Button[]) => {
    const id = uuid.v4();
    this.callbacks[id] = {};
    const buttonsFinal: ButtonAlias[] = [];
    buttons.forEach((button) => {
      const handleId = uuid.v4();
      this.callbacks[id][handleId] = button.handle;
      buttonsFinal.push({
        label: button.label,
        variant: button.variant,
        colorName: button.colorName,
        textColorName: button.textColorName,
        handleId,
      });
    });
    dispatcher.app.dialogShow(id, body, buttonsFinal);
  };

  public hide = (id: string) => {
    dispatcher.app.dialogHide(id);
    delete this.callbacks[id];
  };

  public getHandler = (dialogId: string, handleId: string) => {
    return this.callbacks?.[dialogId]?.[handleId];
  };
}

export default new Controller();
