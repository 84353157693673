import {
  useCallback, useEffect, useRef,
} from 'react';
import type { FC } from 'react';
import { Text, View } from 'components/Themed';
import {
  type NativeSyntheticEvent,
  Pressable, type StyleProp, StyleSheet, TextInput, type TextInputKeyPressEventData, type ViewStyle,
} from 'react-native';

type CodeInputProps = {
  value: string,
  onChange: (value: string) => void,
  onKeyPress: ((e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void),
  style?: StyleProp<ViewStyle>
}

const CodeInput: FC<CodeInputProps> = (props) => {
  const {
    value,
    onChange,
    onKeyPress,
    style,
  } = props;

  const inputRef = useRef<TextInput>(null);

  useEffect(() => {
    const { current } = inputRef;
    if (!current) {
      return;
    }
    current.focus();
  }, []);

  const handleFocus = useCallback(() => {
    const { current } = inputRef;
    if (!current) {
      return;
    }
    current.focus();
  }, []);

  const handleChange = useCallback((event) => {
    onChange?.(event.target.value.replace(/[^0-9]+/, '').substring(0, 6));
  }, [onChange]);

  const finalValue = (value || '').replace(/[^0-9]+/, '').substring(0, 6);
  const letters = finalValue.split('');

  return (
    <Pressable onFocus={handleFocus} style={[style, styles.codeInput, { direction: 'ltr' }]}>
      <TextInput
        ref={inputRef}
        onKeyPress={onKeyPress}
        onChange={handleChange}
        value={finalValue}
        style={styles.input}
        textContentType="oneTimeCode"
      />
      <Text style={styles.digit} size={18}>
        {letters?.[0]}
        {letters.length === 0 && <span>|</span>}
      </Text>
      <Text style={styles.digit} size={18}>
        {letters?.[1]}
        {letters.length === 1 && <span>|</span>}
      </Text>
      <Text style={styles.digit} size={18}>
        {letters?.[2]}
        {letters.length === 2 && <span>|</span>}
      </Text>
      <Text style={styles.digit} size={18}>
        {letters?.[3]}
        {letters.length === 3 && <span>|</span>}
      </Text>
      <Text style={styles.digit} size={18}>
        {letters?.[4]}
        {letters.length === 4 && <span>|</span>}
      </Text>
      <Text style={styles.digit} size={18}>
        {letters?.[5]}
        {letters.length === 5 && <span>|</span>}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  codeInput: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  input: {
    position: 'absolute',
    opacity: 0,
  },
  digit: {
    display: 'flex',
    position: 'relative',
    height: 33,
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid #cacaca',
    borderRadius: 5,
  },
});

export default CodeInput;
