import {
  takeLatest, call, put, cancel, take, delay,
} from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';

import Alert from 'components/Alert';

import * as api from 'services/api';

import * as appStore from 'store/nodes/app';
import * as userStore from 'store/nodes/user';

import { navigate } from 'navigation/methods';
import * as actions from '../actions';

export const config = {
  action: actions.login.type,
  method: takeLatest,
};

type Action = SagaReturnType<typeof actions.login>;
type AuthLoginResult = SagaReturnType<typeof api.resource.auth.login>;

export function* func(action: Action) {
  const { phoneNumber, userPassword } = action.payload;
  const result: AuthLoginResult = yield call(api.resource.auth.login, phoneNumber, userPassword);
  if (result?.error || !result?.data) {
    yield call(() => navigate('Auth/RequestCode'));
    Alert.error(result?.error?.message || 'Server error #5');
    yield put(actions.loginDone(result?.error || 'error_login'));
    yield cancel();
    return;
  }

  const { sessionToken } = result.data;
  if (!sessionToken) {
    yield put(actions.loginDone('error_login'));
    yield cancel(); return;
  }

  yield call(() => api.credentials.set(sessionToken));

  yield put(userStore.actions.loadProfile());
  yield take(userStore.actions.loadProfileDone.type);
  yield delay(10);

  yield put(appStore.actions.route());
  yield take(appStore.actions.routed.type);

  yield put(actions.loginDone());
}
