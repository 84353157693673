/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
import Home from 'screens/Home';
import RequestCode from 'screens/auth/RequestCode';
import Login from 'screens/auth/Login';
import NotFound from 'screens/NotFound';

// import UIBar, { UIBarProvider } from 'components/UIBar';

import { Routes, Route } from '../router';
import linking from '../linking';

const Stack = () => {
  return (
  // <UIBarProvider>
    <Routes>
      <Route path={linking.Home} element={<Home />} />
      <Route path={linking['Auth/RequestCode']} element={<RequestCode />} />
      <Route path={linking['Auth/Login']} element={<Login />} />
      <Route path={linking['Error/NotFound']} element={<NotFound />} />
    </Routes>
  // <UIBar />
  // </UIBarProvider>
  );
};

export default Stack;
