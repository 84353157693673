import {
  type ReactElement,
  memo, useMemo,
} from 'react';
import {
  Platform, View,
} from 'react-native';
import type {
  StyleProp, ViewStyle,
} from 'react-native';

import { type ColorVariants } from 'constants/Colors';
import { unit } from 'components/layout';
import useThemeColor from 'hooks/useThemeColor';

import { useTranslation } from 'translations';
import * as Map from './lib/Map';
import type { IComponent, PlatformOSType } from './lib/types';

import ErrorOutline from './assets/ErrorOutline.svg';
import DocsInReviewIcon from './assets/DocsinReviewIcon.svg';
import DocsSendIcon from './assets/DocsSendIcon.svg';
import PassedReviewIcon from './assets/PassedReviewIcon.svg';
import ArrowIcon from './assets/ArrowIcon.svg';
import ArrowLeftIcon from './assets/ArrowLeftIcon.svg';
import ImageOutline28Icon from './assets/ImageOutline28.svg';
import LogoutIcon from './assets/LogoutIcon.svg';
import CompanyLogoIcon from './assets/CompanyLogoIcon.svg';
import RemoveIcon from './assets/RemoveIcon.svg';
import UploadDocsIcon from './assets/UploadDocsIcon.svg';
import EnLangIcon from './assets/EnLangIcon.svg';
import HeLangIcon from './assets/HeLangIcon.svg';

const mapItems = {
  ErrorOutline: new Map.IconSVG(ErrorOutline, 'fill'),
  DocsInReviewIcon: new Map.IconSVG(DocsInReviewIcon, 'fill'),
  DocsSendIcon: new Map.IconSVG(DocsSendIcon, 'fill'),
  PassedReviewIcon: new Map.IconSVG(PassedReviewIcon, 'fill'),
  ArrowIcon: new Map.IconSVG(ArrowIcon, 'stroke'),
  ArrowLeftIcon: new Map.IconSVG(ArrowLeftIcon, 'fill'),
  ImageOutline28: new Map.IconSVG(ImageOutline28Icon, 'fill'),
  LogoutIcon: new Map.IconSVG(LogoutIcon, 'fill'),
  CompanyLogoIcon: new Map.IconSVG(CompanyLogoIcon, 'fill'),
  RemoveIcon: new Map.IconSVG(RemoveIcon, 'fill'),
  UploadDocsIcon: new Map.IconSVG(UploadDocsIcon, 'stroke'),
  EnLangIcon: new Map.IconSVG(EnLangIcon, 'fill'),
  HeLangIcon: new Map.IconSVG(HeLangIcon, 'fill'),
};
const map = new Map.Items(mapItems);

export type IconNames = keyof typeof mapItems;

const getComponentObject = (
  data: Map.PlatformGroup | Map.IconSVG,
  platform?: PlatformOSType,
): IComponent => {
  if (data instanceof Map.PlatformGroup && typeof platform !== 'undefined') {
    return getComponentObject(data[platform]);
  }

  if (data instanceof Map.IconSVG) {
    return data;
  }

  return mapItems.ErrorOutline;
};

const defaultProps = {
  platform: Platform.OS as PlatformOSType,
};

type IconProps = {
  style?: StyleProp<ViewStyle>,
  name: IconNames,
  size: number,
  lightColor?: string,
  darkColor?: string,
  colorName?: ColorVariants,
  platform?: PlatformOSType,
  direction?: 'ltr' | 'rtl',
} & typeof defaultProps;

const Icon = (props: IconProps): ReactElement | null => {
  const {
    style,
    name,
    size,
    lightColor,
    darkColor,
    colorName,
    platform,
    direction,
  } = props;

  const componentObject = getComponentObject(
    map.getByName(name),
    platform as PlatformOSType,
  );

  const { direction: contextDirection } = useTranslation();

  const color = useThemeColor({
    light: lightColor, dark: darkColor,
  }, colorName as any || 'text');

  const iconStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {};
    const directionFinal = direction || contextDirection || 'ltr';
    if (directionFinal === 'rtl') {
      result.transform = [{
        scaleX: -1,
      }];
    }
    return result;
  }, [direction, contextDirection]);

  const { source: IconComponent, colorProperty, scale } = componentObject;
  const properties = useMemo(() => ({
    width: unit((size || 24) * scale),
    height: unit((size || 24) * scale),
    [colorProperty]: color,
  }), [size, scale, IconComponent, colorProperty, color]);

  if (componentObject instanceof Map.IconSVG) {
    return <View style={[style, iconStyle]}><IconComponent {...properties} /></View>;
  }

  return null;
};

Icon.defaultProps = defaultProps;

export default memo(Icon);
